import NavbarPage from "../Navbar/navbarPage";
import Footer from "../Footer/footer";
import {Link} from "react-router-dom";

const AboutUs = () => {
    return(
        <div className="container-fluid">
            {/*Hero section*/}
            <div className="row bg-hero-about-container pb-4">

                <div className="col-12">
                    {/*Navbar*/}
                    <div className="row justify-content-center mt-4 pt-3 fixed-top">
                        <NavbarPage />
                        <div className="col-11 justify-content-center col-md-10">
                            {/*<nav className="navbar navbar-danger bg-white nav-bar-container d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="row d-flex justify-content-between">*/}
                            {/*        <div className="col-2 font-color-white d-flex justify-content-center align-items-center d-block d-md-none p-0">*/}
                            {/*            <a href="/index.html">*/}
                            {/*                {/*                                <label class="font-color-grey-dark font-size-42 font-family-agatho">Logo</label>*/}
                            {/*                <img className="img-fluid w-100" src="images/landing/le-aira-event-logo.png" alt="logo" />*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="col-3 d-none d-md-flex flex-row justify-content-center align-content-center font-family-manrope">*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/about.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/service-details.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Services*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/gallery.html">*/}
                            {/*                    <button type="button"*/}
                            {/*                            className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Gallery*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            
                            {/*        <div className="col-2 font-color-white d-none d-md-block px-4">*/}
                            {/*            <div className="px-2">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid" src="images/landing/le-aira-event-logo.png" alt="logo" />*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            
                            {/*        <div className="col-4 p-0 d-none d-md-flex flex-column justify-content-center">*/}
                            {/*            <div className="row d-flex align-items-center justify-content-between d-md-flex">*/}
                            {/*                <div className="col-6 p-0">*/}
                            {/*                    <div className="d-flex justify-content-end align-items-center gap-3 w-100">*/}
                            {/*                        <i data-feather="instagram"></i>*/}
                            {/*                        <i data-feather="facebook"></i>*/}
                            {/*                        <i data-feather="youtube"></i>*/}
                            {/*                        <i data-feather="twitter"></i>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-6 d-flex justify-content-center p-0">*/}
                            {/*                    <a href="/contact-us.html">*/}
                            {/*                        <button type="button"*/}
                            {/*                                className="button-book-consult font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                            Book a*/}
                            {/*                            Consult*/}
                            {/*                        </button>*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            
                            {/*        <div className="col-2 d-flex justify-content-center d-md-none">*/}
                            {/*            <button className="navbar-toggler border-none" type="button"*/}
                            {/*                    data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent-2"*/}
                            {/*                    aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                    aria-label="Toggle navigation">*/}
                            {/*                <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            
                            {/*</nav>*/}
                            {/*<div className="collapse mt-2" id="navbarToggleExternalContent-2">*/}
                            {/*    <div className="bg-white rounded-5 p-4  d-flex justify-content-between align-content-center gap-2 flex-column">*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/about.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    About*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/service-details.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Services*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/gallery.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Gallery*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center border-2">*/}
                            {/*            <a href="/contact-us.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className="button-book-consult w-100 media-button-bg font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                    Book a Consult*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="d-flex justify-content-around align-items-center pt-4 gap-2">*/}
                            {/*            <i data-feather="instagram"></i>*/}
                            {/*            <i data-feather="facebook"></i>*/}
                            {/*            <i data-feather="youtube"></i>*/}
                            {/*            <i data-feather="twitter"></i>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*Hero section*/}
                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 my-4"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 my-md-4 py-md-4 my-3"></div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-1 d-none d-md-block"></div>
                                <div className="col-12 col-md-6 text-align-center">
                                    <div className="">
                                        <label className="font-size-48 font-color-white font-weight-300p font-family-agatho">
                                            Our Story
                                        </label>
                                    </div>
                                    <div className="font-align-center">
                                        <label
                                            className="font-size-18 font-weight-400p font-family-manrope font-color-grey-light">
                                            Our story began with a dream –a dream to ignite your event with creative spark. From traditional weddings to grand birthday parties or large-scale corporate parties to movie promotion events, our journey is woven with passion and dedication to turning visions into remarkable events.
                                        </label>
                                    </div>
                                    <div className="font-align-center my-md-4 py-3">
                                        <Link to={"/contactus"}>
                                            <button type="button" className="button-bg font-color-white font-weight-500p font-size-18 font-family-manrope">
                                                Contact us
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-md-4 py-4"></div>
                    </div>
                </div>

            </div>

            {/* services-container */}
            <div className="row bg-about-choose-us justify-content-center px-4">
                <div className="col-10 col-md-8 bg-white bg-about-container bg-about-us-container">
                    <div className=" d-block d-md-flex justify-content-around align-items-center gap-4">
                        <div className="gap-4">
                            <div className="text-center">
                                <label className="font-size-42 font-color-linear-gradient-about font-weight-700p">81</label>
                            </div>
                            <div className="text-center">
                                <label className="font-size-18 font-color-grey-dark font-weight-500p font-family-manrope">Events
                                    Organized</label>
                            </div>
                            <div className="pt-3 mt-1 d-block d-md-none"></div>
                        </div>
                        <div className="gap-4">
                            <div className="text-center">
                                <label className="font-size-42 font-color-linear-gradient-about font-weight-700p">50+</label>
                            </div>
                            <div className="text-center">
                                <label className="font-size-18 font-color-grey-dark font-weight-500p font-family-manrope">Happy
                                    Clients</label>
                            </div>
                            <div className="pt-3 mt-1 d-block d-md-none"></div>
                        </div>
                        <div className="gap-4">
                            <div className="text-center">
                                <label className="font-size-42 font-color-linear-gradient-about font-weight-700p">
                                    3+<sub className="font-size-14 font-color-linear-gradient-about font-weight-700p">years</sub>
                                </label>
                            </div>
                            <div className="text-center">
                                <label
                                    className="font-size-18 font-color-grey-dark font-weight-500p font-family-manrope">Experience</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Why choose us*/}
            <div className="row bg-about-choose-us pb-4">
                <div className="col-12 mt-md-4 mb-md-4 pb-md-2">
                    <div className="row mt-4 pt-md-2">
                        <div className="col-12 text-center ">
                            <div>
                                <label className="font-size-42 font-weight-500p font-family-agatho font-color-neutral-black">Why
                                    Choose Us</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex flex-column gap-2 gap-md-4 px-3">
                            <div className="row mt-md-4">
                                <div className="col-12 mt-md-3">
                                    <div className="row justify-content-center gap-3 gap-md-4 px-2">
                                        <div className="col-12 pt-1 d-block d-md-none"></div>
                                        <div className="col-12 col-md-5 card p-md-4 bg-expertise-across-diverse-events-card">
                                            <div className="p-md-2">
                                                <div className="row pb-md-4">
                                                    <div className="col-4">
                                                        <img className="img-fluid" src="/images/about/message-icon.png" alt="message icon"/>
                                                    </div>
                                                </div>
                                                <div className="pt-3 d-block d-md-none"></div>
                                                <div className="why-choose-us-card-content">
                                                    <div>
                                                        <label
                                                            className="font-size-24 font-weight-600p font-family-manrope font-color-neutral-black">Expertise
                                                            Across Diverse Events</label>
                                                    </div>
                                                    <div className="pt-2 pt-md-3">
                                                        <label
                                                            className="font-size-14 font-weight-500p font-family-manrope font-color-grey-dark">Experience
                                                            Matters: With a rich history of successfully organizing weddings, birthdays,
                                                            corporate events, and movie premieres, our team possesses the expertise to
                                                            transform any
                                                            occasion into a spectacular and memorable event.
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 card p-md-4 bg-creative-vision-card">
                                            <div className="p-md-2">
                                                <div className="row pb-md-4">
                                                    <div className="col-4">
                                                        <img className="img-fluid" src="/images/about/bulb-icon.png" alt="bulb icon"/>
                                                    </div>
                                                </div>
                                                <div className="pt-3 d-block d-md-none"></div>
                                                <div className="why-choose-us-card-content">
                                                    <div className="mb-md-3">
                                                        <label
                                                            className="font-size-24 font-weight-600p font-family-manrope font-color-neutral-black">Creative
                                                            Vision and Innovation</label>
                                                    </div>
                                                    <div className="pt-2 d-block d-md-none"></div>
                                                    <label className="font-size-14 font-weight-500p font-family-manrope font-color-grey-dark">Unleash
                                                        Your Imagination: We don't just plan events; we bring visions to life.
                                                        Our creative team thrives on innovative ideas, pushing the boundaries to ensure your
                                                        event is a unique and visually stunning experience.
                                                    </label>
                                                </div>
                                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row d-flex justify-content-center gap-3 gap-md-4 px-2">
                                        <div className="col-12 col-md-4 card p-md-4 bg-client-approach-card">
                                            <div className="p-md-2">
                                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                                                <div className="row pb-md-4">
                                                    <div className="col-4">
                                                        <img className="img-fluid" src="/images/about/client-approach-icon.png"
                                                             alt="client icon"/>
                                                    </div>
                                                </div>
                                                <div className="pt-3 d-block d-md-none"></div>
                                                <div className="why-choose-us-card-content">
                                                    <div className="mb-md-3">
                                                        <label
                                                            className="font-size-24 font-weight-600p font-family-manrope font-color-neutral-black">Client-Centric
                                                            Approach</label>
                                                    </div>
                                                    <div className="pt-2 d-block d-md-none"></div>
                                                    <label className="font-size-14 font-weight-500p font-family-manrope font-color-grey-dark">We
                                                        believe in collaborative planning, ensuring your vision takes center stage.
                                                        Our client-centric approach means open communication, commitment to exceeding your
                                                        expectations.
                                                        Your satisfaction is our ultimate goal.
                                                    </label>
                                                </div>
                                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 card p-md-4 bg-personalized-service-card">
                                            <div className="p-md-2">
                                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                                                <div className="row pb-md-4">
                                                    <div className="col-4">
                                                        <img className="img-fluid" src="/images/about/settings-icon.png" alt="settings icon"/>
                                                    </div>
                                                </div>
                                                <div className="pb-3 d-block d-md-none"></div>
                                                <div className="why-choose-us-card-content">
                                                    <div className="mb-md-3">
                                                        <label className="font-size-24 font-weight-600p font-family-manrope font-color-neutral-black">Personalized
                                                            Service</label>
                                                    </div>
                                                    <div className="pb-2 d-block d-md-none"></div>
                                                    <div className="p-0">
                                                        <label className="font-size-14 font-weight-500p font-family-manrope font-color-grey-dark">Our
                                                            dedicated team goes the extra mile to understand client needs,
                                                            communicate transparently, and deliver a personalized, stress-free experience,
                                                            making every event journey enjoyable and memorable.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*our values*/}
            <div className="row bg-about-choose-us py-4">
                <div className="col-12 py-md-4 my-md-2">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="row justify-content-center align-items-start">
                                <div className="col-12 col-md-5 ">
                                    <div className="p-1 px-md-2 text-align-center">
                                        <div className="">
                                            <div>
                                                <label className="font-size-42 font-weight-500p font-family-agatho font-color-neutral-black">
                                                    Our Values
                                                </label>
                                            </div>
                                            <div>
                                                <label
                                                    className="font-size-14 font-weight-400p font-color-grey-medium font-family-manrope">Our
                                                    core values—excellence, creativity, and unwavering dedication to client
                                                    satisfaction.
                                                    These principles shape our every move, ensuring that your event journey is not
                                                    just successful
                                                    but a joyous celebration of the unique moments that matter most to you.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="pt-md-4 my-3 pb-1 pb-md-0">
                                            <Link to={"/contactus"}>
                                                <button type="button"
                                                        className="button-bg font-color-white font-size-18 font-weight-500p font-family-manrope">
                                                    Contact us
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="d-flex flex-row justify-content-start align-items-center gap-2 gap-md-4 pb-4 mb-md-3">
                                        <img src="/images/about/trophy-icon.png" alt="trophy icon"/>
                                            <div>
                                                <label
                                                    className="font-size-18 font-weight-400p font-color-grey-medium font-family-manrope">
                                                    <span className="font-weight-500p font-color-neutral-black">Creativity:</span>
                                                    Bringing fresh and imaginative ideas to the table, tailored to each client's
                                                    unique vision.
                                                </label>
                                            </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-start align-items-center gap-2 gap-md-4 pb-4 mb-md-3">
                                        <img src="/images/about/flag-icon.png" alt="flag icon"/>
                                            <div>
                                                <label
                                                    className="font-size-18 font-weight-400p font-color-grey-medium font-family-manrope">
                                                    <span className="font-weight-500p font-color-neutral-black">Excellence: </span>
                                                    Upholding a commitment to excellence in every aspect of event planning and
                                                    execution.
                                                </label>
                                            </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-start align-items-center gap-2 gap-md-4">
                                        <img src="/images/about/satisfaction-icon.png" alt="client satisfaction icon"/>
                                        <div>
                                            <label className="font-size-18 font-weight-400p font-color-grey-medium font-family-manrope">
                                            <span className="font-weight-500p font-color-neutral-black">Client Satisfaction:</span>
                                                Prioritizing the client's needs and vision throughout the event planning
                                                process.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/*Team member*/}

            <div className="row bg-about-choose-us pb-4">
                <div className="col-12 pt-4">
                    <div className="row mt-md-2">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <div className="mt-md-4">
                                <label className="font-size-42 font-weight-500p font-family-agatho font-color-neutral-black">
                                    Team Members
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row d-flex flex-row justify-content-center align-content-center pt-md-4">
                        <div className="col-12 col-md-10 pt-md-3">
                            <div className="row gap-4 gap-md-5 mb-md-5">
                                <div className="col-12 d-none d-md-flex justify-content-center gap-4 ">
                                    <div className="team-member-card text-center pb-3 pb-md-0">
                                        <div className="team-member-card-img">
                                            <img className="img-fluid " src="/images/about/team-member-image.png" alt="team member"/>
                                        </div>
                                        <div className="team-member-description opacity-0 pb-3 pb-md-0 ">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <label className="font-size-18 font-color-beige-dark font-weight-500p font-family-manrope">Robert
                                                    Fox</label>
                                                <label className="font-size-12 font-color-beige-new-darker font-weight-500p font-family-manrope">Event
                                                    Manager</label>
                                                <label className="font-size-12 font-color-grey-medium font-weight-500p font-family-manrope">Photography is
                                                    my passion and my craft. Let me share it with you.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="team-member-card text-center pb-3 pb-md-0 ">
                                        <div className="team-member-card-img">
                                            <img className="img-fluid" src="/images/about/team-member-image.png" alt="team member"/>
                                        </div>
                                        <div className="team-member-description pb-3 pb-md-0  opacity-0">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <label className="font-size-18 font-color-beige-dark font-weight-500p font-family-manrope">Robert
                                                    Fox</label>
                                                <label className="font-size-12 font-color-beige-new-darker font-weight-500p font-family-manrope">Event
                                                    Manager</label>
                                                <label className="font-size-12 font-color-grey-medium font-weight-500p font-family-manrope">Photography is
                                                    my passion and my craft. Let me share it with you.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" text-center">
                                        {/*<div  className="team-member-card-img">*/}
                                        {/*    <img className="img-fluid" src="/images/about/team-member-image.png" alt="team member"/>*/}
                                        {/*</div>*/}
                                        {/*<div className="team-member-description opacity-0 ">*/}
                                        {/*    <div className="d-flex flex-column justify-content-center align-items-center">*/}
                                        {/*        <label className="font-size-18 font-color-beige-dark font-weight-500p font-family-manrope">Robert*/}
                                        {/*            Fox</label>*/}
                                        {/*        <label className="font-size-12 font-color-beige-new-darker font-weight-500p font-family-manrope">Event*/}
                                        {/*            Manager</label>*/}
                                        {/*        <label className="font-size-12 font-color-grey-medium font-weight-500p font-family-manrope">Photography is*/}
                                        {/*            my passion and my craft. Let me share it with you.</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-12 d-none justify-content-center gap-4">
                                    {/*<div className="team-member-card text-center pb-3 pb-md-0 ">*/}
                                    {/*    <div className="team-member-card-img" >*/}
                                    {/*        <img className="img-fluid" src="/images/about/team-member-image.png" alt="team member"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="team-member-description pb-3 pb-md-0 opacity-0">*/}
                                    {/*        <div className="d-flex flex-column justify-content-center align-items-center">*/}
                                    {/*            <label className="font-size-18 font-color-beige-dark font-weight-500p font-family-manrope">Robert*/}
                                    {/*                Fox</label>*/}
                                    {/*            <label className="font-size-12 font-color-beige-new-darker font-weight-500p font-family-manrope">Event*/}
                                    {/*                Manager</label>*/}
                                    {/*            <label className="font-size-12 font-color-grey-medium font-weight-500p font-family-manrope">Photography is*/}
                                    {/*                my passion and my craft. Let me share it with you.</label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="team-member-card text-center pb-3 pb-md-0 ">*/}
                                    {/*    <div className="team-member-card-img">*/}
                                    {/*        <img className="img-fluid" src="/images/about/team-member-image.png" alt="team member"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="team-member-description d-flex  justify-content-center align-items-center opacity-0">*/}
                                    {/*        <div className="d-flex flex-column justify-content-center align-items-center">*/}
                                    {/*            <div className="text-center">*/}
                                    {/*                <label className="font-size-18 font-color-beige-dark font-weight-500p font-family-manrope">Robert*/}
                                    {/*                    Fox</label>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="text-center">*/}
                                    {/*                <label className="font-size-12 font-color-beige-new-darker font-weight-500p font-family-manrope">Event*/}
                                    {/*                    Manager</label>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="text-center">*/}
                                    {/*                <label className="font-size-12 font-color-grey-medium font-weight-500p font-family-manrope">Photography is*/}
                                    {/*                    my passion and my craft. Let me share it with you.</label>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-block d-md-none gap-4">
                            <div className=" py-2 text-center">
                                <div>
                                    <img className="img-fluid" src="/images/about/team-member-image.png" alt="team member"/>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <label className="font-size-18 font-color-beige-dark font-weight-500p font-family-manrope">Robert
                                            Fox</label>
                                        <label className="font-size-12 font-color-beige-new-darker font-weight-500p font-family-manrope">Event
                                            Manager</label>
                                        <label className="font-size-12 font-color-grey-medium font-weight-500p font-family-manrope">Photography is
                                            my passion and my craft. Let me share it with you.</label>
                                    </div>
                                </div>
                            </div>
                            <div className=" py-2 text-center">
                                <div>
                                    <img className="img-fluid" src="/images/about/team-member-image.png" alt="team member"/>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <label className="font-size-18 font-color-beige-dark font-weight-500p font-family-manrope">Robert
                                            Fox</label>
                                        <label className="font-size-12 font-color-beige-new-darker font-weight-500p font-family-manrope">Event
                                            Manager</label>
                                        <label className="font-size-12 font-color-grey-medium font-weight-500p font-family-manrope">Photography is
                                            my passion and my craft. Let me share it with you.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Footer section*/}

            <Footer/>

        </div>
    )
}

export default AboutUs
