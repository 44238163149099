import * as Icon from 'react-feather'
import {Link} from "react-router-dom";
import servicesData from '../Models/services.json'

const Footer = () => {
    return (
        <div className="row footer-bg-container">
            <div className="col-12 mt-4 pt-4 font-family-manrope font-weight-500p font-size-18 ">
                <div className="row d-flex flex-row justify-content-center align-items-start pt-4 mt-2">
                    <div className="col-12 col-md-4">
                        <div className="footer-logo-container row">
                            <div className="mb-md-4 pb-md-4 pb-3 col-3">
                                <Link to={"/"}>
                                    <img className="img-fluid" src={"/images/landing/le-aira-event-logo.png"} alt="logo" />
                                </Link>
                            </div>
                            <label className="font-size-12 font-weight-500p font-color-white font-family-manrope">
                                © 2024 events.
                                All rights are reserved</label>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="footer-details-container d-flex flex-row justify-content-around">
                            <div className="py-3 mt-2 d-block d-md-none"></div>
                            <div className="footer-about-container d-flex flex-column font-family-manrope ">
                                <div className="mb-3">
                                    <Link to={"/"} >
                                        <button type={"button"} className=" bg-transparent border-none p-0  font-color-white font-size-14 font-weight-500p">Home</button>
                                    </Link>
                                </div>
                                <div className="mb-3">
                                    <Link to={"/about"}>
                                        <button type={"button"} className=" bg-transparent border-none p-0  font-color-white font-size-14 font-weight-500p ">About</button>
                                    </Link>
                                </div>
                               <div className="mb-3">
                                   <Link to={`/services/${servicesData[0].service_slug}`}>
                                       <button type={"button"} className="bg-transparent border-none p-0  font-color-white font-size-14 font-weight-500p ">Services</button>
                                   </Link>
                               </div>
                                <div className="mb-3">
                                    <Link to={"/gallery"}>
                                        <button type={"button"} className="bg-transparent border-none p-0  font-color-white font-size-14 font-weight-500p ">Gallery</button>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={"/contactus"}>
                                        <button type={"button"} className=" bg-transparent border-none p-0 font-color-white font-size-14 font-weight-500p">Contact us</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 footer-address-container d-flex flex-column justify-content-start font-family-manrope">
                                <div className="py-3 mt-2 d-block d-md-none"></div>
                                <div className="pb-3 d-flex gap-2">
                                    <div>
                                        <Icon.Phone className="text-white "/>
                                    </div>
                                    <label className="font-size-14 font-weight-500p font-color-white">
                                        040-45026990
                                    </label>
                                </div>
                                <div className="pb-3 d-flex gap-2">
                                    <div>
                                        <Icon.Mail className="text-white "/>
                                    </div>
                                    <div>
                                        <a href="mailto:Operations@leairaevents.com" className="font-color-white font-size-14 font-weight-500p " style={{textDecorationLine: "none"}}>
                                            Operations@leairaevents.com
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex gap-2">
                                    <div>
                                        <Icon.MapPin className="text-white "/>
                                    </div>
                                    <label className="pb-3 text-break text-white font-size-14 font-weight-500p ">
                                        4th Floor, And 5, Plot No: 4,
                                        Arunodaya Colony, Vittal Rao Nagar,
                                        Madhapur, Hyderabad, Telangana - 500081
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-4 footer-logo d-flex flex-row justify-content-end px-2">
                    <div className="col-6 col-md-2">
                        <div className="designed-by-container p-2">
                            <label className="font-color-beige-new-darker font-size-12 font-family-manrope font-weight-400p">
                                Designed by: <a href={"https://7dotss.com/"} target={"_blank"}><img className="img-fluid" src={"/images/landing/7-dots-logo.png"} alt="7 dots logo"/></a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
