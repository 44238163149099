import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom';
import * as Icon from 'react-feather'
import servicesData from '../Models/services.json'

const NavbarPage = () => {
    return (
        <div className={'col-md-10 col-11'}>
            <Navbar collapseOnSelect expand="md" className="bg-white nav-bar-container font-family-manrope p-md-0">
                <Container className={'row'}>
                    <Navbar.Collapse id="responsive-navbar-nav" className={"col-5 d-none d-md-flex"}>
                        <Nav className="">
                            <Nav>
                                <Link to={"/about"}>
                                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">
                                        About
                                    </button>
                                </Link>
                            </Nav>
                            <Nav>
                                <Link to={`/services/${servicesData[0].service_slug}`}>
                                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">
                                        Services
                                    </button>
                                </Link>
                            </Nav>
                            <Nav>
                                <Link to={"/gallery"}>
                                    <button type="button" className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">
                                        Gallery
                                    </button>
                                </Link>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand className={"col-6 ps-4 pe-0 d-flex d-md-none"}>
                        <div className="row justify-content-start p-0">
                            <div className="col-8 ps-4 pe-0 font-color-white d-flex justify-content-center align-items-center">
                                <Link to={"/"}>
                                    <img className="img-fluid w-100" src={"/images/landing/le-aira-event-logo.png"} alt="logo" />
                                </Link>
                            </div>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className={"col-3 border-0"} />

                    <Navbar.Brand className={"col-3 d-none d-md-block "}>
                        <div className="row">
                            <div className="col-6 font-color-white d-flex justify-content-center align-items-center ">
                                <Link to={"/"}>
                                    <img className="img-fluid w-100" src={"/images/landing/le-aira-event-logo.png"} alt="logo" />
                                </Link>
                            </div>
                        </div>
                    </Navbar.Brand>

                    <Navbar.Collapse id="" className={"col-md-4 col-12 d-md-flex align-items-center justify-content-md-end justify-content-center"}>
                        <Nav className="d-md-none d-flex flex-column align-items-center">
                            <Nav>
                                <Link to={"/about"}>
                                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">
                                        About
                                    </button>
                                </Link>
                            </Nav>
                            <Nav>
                                <Link to={`/services/${servicesData[0].service_slug}`}>
                                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">
                                        Services
                                    </button>
                                </Link>
                            </Nav>
                            <Nav>
                                <Link to={"/gallery"}>
                                    <button type="button" className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">
                                        Gallery
                                    </button>
                                </Link>
                            </Nav>
                        </Nav>
                        <Nav className={"d-md-flex align-items-center gap-3 justify-content-between"}>
                            <Nav className={'d-flex flex-row gap-3 gap-md-0  order-1 order-md-0'}>
                                <Nav.Link href="https://www.instagram.com/leairaevents?igsh=MnJuNDA0djE2bWxs&utm_source=qr" target={"_blank"} className={"d-flex"}>
                                    <Icon.Instagram/>
                                </Nav.Link>
                                <Nav.Link href="https://www.facebook.com/share/5AzjxkjVyC8qARjA/?mibextid=LQQJ4d" target={"_blank"} className={"d-flex"}>
                                    <Icon.Facebook/>
                                </Nav.Link>
                                <Nav.Link href="https://www.youtube.com/@LeairaEvents" target={"_blank"} className={"d-flex"}>
                                    <Icon.Youtube/>
                                </Nav.Link>
                                <Nav.Link href="https://x.com/le_aira" target={"_blank"} className={"d-flex"}>
                                    <Icon.Twitter/>
                                </Nav.Link>
                            </Nav>
                            <Nav>
                                <Link to={"/contactus"}>
                                    <button type="button" className="button-book-consult font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">
                                        Book a Consult
                                    </button>
                                </Link>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavbarPage
