import NavbarPage from "../Navbar/navbarPage";
import Footer from "../Footer/footer";
import {Link} from "react-router-dom";
import * as Icon from "react-feather"
import servicesData from '../Models/services.json'
import {useState} from "react";
import {TailSpin} from "react-loader-spinner";

const ContactUs = () => {
    const [loadingButton, setLoadingButton] = useState(false);

    const handleOnSubmitForm = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('contact-form');
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,

        })
            .then(() => {
                alert("Success!");
                setLoadingButton(false);
                form.reset();
            }).catch((error) => {
            alert("Failure!");
            setLoadingButton(false);
            console.log(error)
        })
    }

    return (
        <div className="container-fluid">

            {/*hero section */}
            <div
                className="row bg-contact-us-hero-section d-flex flex-column justify-content-center align-items-center">
                <div className="col-12">
                    {/*Navbar section*/}
                    <div className="row justify-content-center mt-4 pt-3 fixed-top">
                        <NavbarPage/>
                        <div className="col-11 justify-content-center col-md-10">
                            {/*<nav className="navbar navbar-danger bg-white nav-bar-container d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="row d-flex justify-content-between">*/}
                            {/*        <div className="col-2 font-color-white d-flex justify-content-center align-items-center d-block d-md-none p-0">*/}
                            {/*            <a href="/index.html">*/}
                            {/*                /!*                                <label class="font-color-grey-dark font-size-42 font-family-agatho">Logo</label>*!/*/}
                            {/*                <img className="img-fluid w-100" src="images/landing/le-aira-event-logo.png" alt="logo" />*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="col-3 d-none d-md-flex flex-row justify-content-center align-content-center font-family-manrope">*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/about.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/service-details.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Services*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/gallery.html">*/}
                            {/*                    <button type="button"*/}
                            {/*                            className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Gallery*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-2 font-color-white d-none d-md-block px-4">*/}
                            {/*            <div className="px-2">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid" src="images/landing/le-aira-event-logo.png" alt="logo" />*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-4 p-0 d-none d-md-flex flex-column justify-content-center">*/}
                            {/*            <div className="row d-flex align-items-center justify-content-between d-md-flex">*/}
                            {/*                <div className="col-6 p-0">*/}
                            {/*                    <div className="d-flex justify-content-end align-items-center gap-3 w-100">*/}
                            {/*                        <i data-feather="instagram"></i>*/}
                            {/*                        <i data-feather="facebook"></i>*/}
                            {/*                        <i data-feather="youtube"></i>*/}
                            {/*                        <i data-feather="twitter"></i>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-6 d-flex justify-content-center p-0">*/}
                            {/*                    <a href="/contact-us.html">*/}
                            {/*                        <button type="button"*/}
                            {/*                                className="button-book-consult font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                            Book a*/}
                            {/*                            Consult*/}
                            {/*                        </button>*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-2 d-flex justify-content-center d-md-none">*/}
                            {/*            <button className="navbar-toggler border-none" type="button"*/}
                            {/*                    data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent-2"*/}
                            {/*                    aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                    aria-label="Toggle navigation">*/}
                            {/*                <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</nav>*/}
                            {/*<div className="collapse mt-2" id="navbarToggleExternalContent-2">*/}
                            {/*    <div className="bg-white rounded-5 p-4  d-flex justify-content-between align-content-center gap-2 flex-column">*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/about.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    About*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/service-details.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Services*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/gallery.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Gallery*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center border-2">*/}
                            {/*            <a href="/contact-us.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className="button-book-consult w-100 media-button-bg font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                    Book a Consult*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="d-flex justify-content-around align-items-center pt-4 gap-2">*/}
                            {/*            <i data-feather="instagram"></i>*/}
                            {/*            <i data-feather="facebook"></i>*/}
                            {/*            <i data-feather="youtube"></i>*/}
                            {/*            <i data-feather="twitter"></i>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*hero section*/}
                    <div className="row">
                        <div className="col-12">
                            <div className="my-4 py-4 py-md-0"></div>
                        </div>
                    </div>

                    <div className="row d-flex flex-row justify-content-center align-items-start ">
                        <div className="col-12 col-md-4 d-flex justify-content-center align-items-center pt-4 ">
                            <div
                                className="d-flex flex-column justify-content-center align-items-md-start align-items-center mt-md-4 mt-2 pt-md-4 px-2 px-md-4">
                                <div className="mt-md-4"></div>
                                <div className="mt-md-4"></div>
                                <div className="bg-your-vision-mission-card">
                                    <label
                                        className="font-size-32 font-weight-300p font-family-agatho font-color-beige-new-darker font-color-neutral-white">Dream
                                        it, we'll theme it</label>
                                </div>
                                <div className="pt-4 mt-2 transform-your-vision-container">
                                    <label
                                        className="font-size-48 font-weight-500p font-family-agatho font-color-neutral-black font-color-neutral-white">From
                                        planning to execution, let’s make it a grand occasion.</label>
                                </div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="d-none d-md-flex flex-column gap-4 pt-4">
                                    <div className="d-flex flex-row justify-content-start align-items-start gap-4">
                                        <div>
                                            <Icon.Phone className="font-color-beige-new-darker"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start gap-2">
                                            <label
                                                className="font-color-beige-new-darker font-size-18 font-family-manrope font-weight-500p">Phone</label>
                                            <div>
                                                <label
                                                    className="font-size-18 font-weight-400p font-family-manrope font-color-grey-new">040-45026990</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-start align-items-start gap-4">
                                        <div>
                                            <Icon.Mail className="font-color-beige-new-darker"/>
                                        </div>
                                        <div className="d-flex flex-column gap-2">
                                            <label
                                                className="font-color-beige-new-darker font-size-18 font-family-manrope font-weight-500p">Email</label>
                                            <div>
                                                <a href={"mailto:Operations@leairaevents.com"}
                                                   className={"font-size-18 font-weight-400p font-family-manrope font-color-grey-new"}
                                                   style={{textDecorationLine: "none"}}>
                                                    Operations@leairaevents.com
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-start align-items-start gap-4">
                                        <div>
                                            <Icon.MapPin className="font-color-beige-new-darker"/>
                                        </div>
                                        <div className="d-flex flex-column gap-2">
                                            <label
                                                className="font-color-beige-new-darker font-size-18 font-family-manrope font-weight-500p">Location</label>
                                            <label
                                                className="font-size-18 font-weight-400p font-family-manrope font-color-grey-new">
                                                4th Floor, And 5, Plot No: 4,
                                                Arunodaya Colony, Vittal Rao Nagar,
                                                Madhapur, Hyderabad, Telangana - 500081
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-4 pt-md-4">
                            <div className="row mt-md-4"></div>
                            <div className="row mt-md-4"></div>

                            <div className="d-flex justify-content-end mt-md-4 mt-2 pt-4">
                                <form className="form-container d-flex flex-column gap-md-4 gap-3" id="contact-form"
                                      onSubmit={handleOnSubmitForm} method="POST"
                                      action="https://script.google.com/macros/s/AKfycbxCSyXl0D3xtxt3kJcexCb0mE_R234dGf7uAuokQM-1D5jqJBtQdyHI6lbuUlOe7rEw/exec">
                                    <div>
                                        <label htmlFor="exampleFormControlNameInput-"
                                               className="form-label font-family-manrope font-color-white font-size-18 font-weight-500p">Full
                                            Name </label>
                                        <input type='text' name="name" required={true}
                                               className="form-control py-3 bg-input-field"
                                               id="exampleFormControlNameInput-"
                                               placeholder="Enter your full name"/>
                                    </div>

                                    <div className="">
                                        <label htmlFor="exampleFormControlEmailInput"
                                               className="form-label font-family-manrope font-color-white font-size-18 font-weight-500p">Email </label>
                                        <input type="email" name="email" required={true}
                                               className="form-control py-3 bg-input-field"
                                               id="exampleFormControlEmailInput"
                                               placeholder="Enter your email"/>
                                    </div>

                                    <div className="form-row row gap-md-0 gap-3">
                                        <div className="col-12 form-group col-md-6">
                                            <label htmlFor="exampleFormControlCategoryInput"
                                                   className="form-label font-family-manrope font-color-white font-size-18 font-weight-500p">Category </label>
                                            <select type={"select"} name="category"
                                                    className="form-control py-3 bg-input-field"
                                                    id="exampleFormControlCategoryInput">
                                                <option value={""} selected={true} disabled={true}>select category
                                                </option>
                                                {servicesData.map(eachService => (
                                                    <option className={"font-size-14 font-weight-400p"}
                                                            key={eachService.id}
                                                            value={eachService.service_name}>{eachService.service_name}</option>
                                                ))}
                                                {/*<option value="Wedding">Wedding</option>*/}
                                                {/*<option value="Birthday">Birthday</option>*/}
                                                {/*<option value="Catering">Catering</option>*/}
                                                {/*<option value="Corporate">Corporate</option>*/}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="exampleFormControlDateInput"
                                                   className="form-label font-family-manrope font-color-white font-size-18 font-weight-500p">Date</label>
                                            <input type="date" name="date" className="form-control py-3 bg-input-field"
                                                   id="exampleFormControlDateInput"
                                                   placeholder="select your date"/>
                                        </div>
                                    </div>

                                    <div className="form-row row gap-md-0 gap-3 ">
                                        <div className="col-12 form-group col-md-6">
                                            <label htmlFor="exampleFormControlVenueInput"
                                                   className="form-label font-family-manrope font-color-white font-size-18 font-weight-500p">Venue </label>
                                            <input type="text" name="venue" className="form-control py-3 bg-input-field"
                                                   placeholder="Enter venue" id="exampleFormControlVenueInput"/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="exampleFormControlBudgetInput"
                                                   className="form-label font-family-manrope font-color-white font-size-18 font-weight-500p">Budget</label>
                                            <input type="number" name="budget"
                                                   className="form-control py-3 bg-input-field"
                                                   id="exampleFormControlBudgetInput"
                                                   placeholder="Enter budget"/>
                                        </div>
                                    </div>

                                    <div className="">
                                        <label htmlFor="exampleFormControlTextarea"
                                               className="form-label font-family-manrope font-color-white font-size-18 font-weight-500p">Additional
                                            note</label>
                                        <textarea className="form-control bg-input-field" name="message"
                                                  id="exampleFormControlTextarea"
                                                  placeholder="Enter your note" rows="5"></textarea>
                                        <button type="submit" disabled={loadingButton}
                                                className=" col-12 button-bg font-family-manrope d-flex justify-content-center font-color-white font-size-18 font-weight-500p mt-4">
                                            {!loadingButton ? 'Submit' :
                                                <TailSpin height={20} width={20} strokeWidth={4} color={'#FFFFFF'}/>}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*location-container*/}
            <div className="row bg-contact-us-container pb-2">
                <div className="col-12 d-flex flex-column d-md-none gap-2 pt-4">
                    <div className="d-flex flex-row justify-content-start align-items-start gap-2">
                        <div>
                            <Icon.Phone className="font-color-beige-new-darker"/>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <label
                                className="font-color-beige-new-darker font-size-18 font-family-manrope font-weight-500p">Phone</label>
                            <label
                                className="font-size-18 font-weight-400p font-family-manrope font-color-grey-new">040-45026990</label>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-start align-items-start gap-2">
                        <div>
                            <Icon.Mail className="font-color-beige-new-darker"/>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <label
                                className="font-color-beige-new-darker font-size-18 font-family-manrope font-weight-500p">Email</label>
                            <div>
                                <a href={"mailto:Operations@leairaevents.com"}
                                   className={"font-size-18 font-weight-400p font-family-manrope font-color-grey-new"}
                                   style={{textDecorationLine: "none"}}>
                                    Operations@leairaevents.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-start align-items-start gap-2">
                        <div>
                            <Icon.MapPin className="font-color-beige-new-darker"/>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <label
                                className="font-color-beige-new-darker font-size-18 font-family-manrope font-weight-500p">Location</label>
                            <label className="font-size-18 font-weight-400p font-family-manrope font-color-grey-new">
                                4th Floor, And 5, Plot No: 4,
                                Arunodaya Colony, Vittal Rao Nagar,
                                Madhapur, Hyderabad, Telangana - 500081
                            </label>
                        </div>
                    </div>
                </div>
                {/*location image*/}
                <div className="col-12 d-flex flex-column justify-content-center align-items-center py-4 my-4">
                    <div className="py-2 w-100">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15225.32927725662!2d78.36806394340846!3d17.443801371514475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915fc7db5565%3A0x413ea669206a9152!2sArunodaya%20Colony%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1719291089171!5m2!1sen!2sin"
                            width="100%" height="400" style={{border: 0}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        {/*<img className="img-fluid" src={"/images/contact-us/location-map.png"} alt={"location-image"}/>*/}
                    </div>
                </div>

                {/*your one-stop solution container*/}
                <div className="col-12 pt-2"></div>
                <div className="col-12 d-flex justify-content-center my-md-4 py-md-4">
                    <div className="bg-your-one-stop-solution-container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <label
                                        className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                        one-stop
                                        solution for all your event management needs</label>
                                    <div className="pt-3">
                                        <label
                                            className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">
                                            Your dream event is just one step away from making you say 'wow.'
                                        </label>
                                    </div>
                                </div>
                                <div className="pt-4 mt-3 text-center">
                                    <Link to={"/contactus"}>
                                        <button
                                            className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                            Contact us
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Footer section*/}
            <Footer/>
        </div>
    )
}

export default ContactUs
