import exploreServices from '../Models/services.json'
import * as Icon from 'react-feather'
import {Link} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css"
import React, {useState} from "react";

const ExploreServicesPage = () => {

    const [count, setCount] = useState(4);
    const [showServices, setShowServices] = useState(true);

    const handleShowServices = () => {
        setCount(exploreServices.length);
        setShowServices(false);
    }

    const handleHideServices = () => {
        setCount(4);
        setShowServices(true);
    }

    return (
        <>
            {exploreServices.slice(0,count).map(each => (
                <div className="row our-services-card-container mb-4" key={each.id}>
                    <div className="col-12">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-3 p-3 ">
                                <img className="img-fluid rounded-circle" src={each.service_image_url} alt={each.alt}/>
                            </div>
                            <div className=" col-12 col-md-8 our-services-card-content px-md-3 py-md-4">
                                <div className="mb-2">
                                   <div>
                                       <p className="font-size-42 font-weight-500p mb-0 font-color-neutral-black font-family-agatho media-font-size-20">
                                           {each.service_name}
                                       </p>
                                   </div>
                                    <div>
                                        <p className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12">
                                            {each.service_description}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <Link to={`/services/${each.service_slug}`} style={{textDecoration: "none"}}>
                                        <p className="font-color-beige-dark font-size-18 mb-0 font-weight-500p font-family-manrope media-font-size-12">
                                            See more <Icon.ArrowRight />
                                        </p>
                                    </Link>
                                </div>
                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div className="col-12 pb-md-4 mb-md-2">
                <div className="pt-3 mt-1 d-block d-md-none"></div>
                <div className="d-flex justify-content-center align-items-center mt-md-4 pt-md-3 ">
                    {showServices && (<button className="button-bg font-family-manrope font-color-beige-light font-size-18 font-weight-500p" onClick={handleShowServices}>
                        Explore all services <Icon.ChevronDown />
                    </button>)}

                    {!showServices && (<button className="button-bg font-family-manrope font-color-beige-light font-size-18 font-weight-500p" onClick={handleHideServices}>
                        Show less <Icon.ChevronUp />
                    </button>)}

                </div>
            </div>
        </>
    )
}

export default  ExploreServicesPage
