import NavbarPage from "../Navbar/navbarPage";
import Footer from "../Footer/footer";
import {Link} from 'react-router-dom'
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import 'swiper/css';
import "swiper/css/autoplay";

import * as Icon from 'react-feather'
import {useEffect, useState} from "react";
import ExploreServicesPage from "../OurServicesPage/exploreServicesPage";
import ourServicesGallery from "../Models/ourServicesGallery.json";

const HomePage = () => {

    const [reviewSwiper, setReviewSwiper] = useState(null);
    const [swiper, setGallerySwiper] = useState(null);
    const [imageGalleryIndex, setImageGalleryIndex] = useState(0);
    const galleryData = ourServicesGallery.map(each => each.our_gallery);
    console.log(galleryData)

    const goGalleryNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goGalleryPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const goReviewNext = () => {
        if (reviewSwiper !== null) {
            reviewSwiper.slideNext();
        }
    };

    const goReviewPrev = () => {
        if (reviewSwiper !== null) {
            reviewSwiper.slidePrev();
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Increment the image index
            console.log("image index", imageGalleryIndex)
            // console.log("length",tabsData[imageIndex]?.service_gallery?.first_image?.length)
            setImageGalleryIndex((prevIndex) => (prevIndex + 1) % galleryData[0]?.length);
        }, 1000); // Change image every second (1000 milliseconds)
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    return (
        <div className="container-fluid">

            <div className="row bg-hero-section-container pb-4">
                <div className="col-12 pb-4">
                    {/*Navbar*/}
                    <div className="row justify-content-center mt-4 pt-3 fixed-top">
                        <NavbarPage/>
                        <div className="col-11 justify-content-center col-md-10">
                            {/*<nav className="navbar navbar-danger bg-white nav-bar-container d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="row d-flex justify-content-between">*/}
                            {/*        <div className="col-2 font-color-white d-flex justify-content-center align-items-center d-block d-md-none p-0">*/}
                            {/*            <a href="/index.html">*/}
                            {/*                /!*                                <label className="font-color-grey-dark font-size-42 font-family-agatho">Logo</label>*!/*/}
                            {/*                <img className="img-fluid w-100" src={"images/landing/le-aira-event-logo.png"} alt="logo" />*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="col-3 d-none d-md-flex flex-row justify-content-center align-content-center font-family-manrope">*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/about.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/service-details.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Services*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/gallery.html">*/}
                            {/*                    <button type="button"*/}
                            {/*                            className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Gallery*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-2 font-color-white d-none d-md-block px-4">*/}
                            {/*            <div className="px-2">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid" src={"images/landing/le-aira-event-logo.png"} alt="logo" />*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-4 p-0 d-none d-md-flex flex-column justify-content-center">*/}
                            {/*            <div className="row d-flex align-items-center justify-content-between d-md-flex">*/}
                            {/*                <div className="col-6 p-0">*/}
                            {/*                    <div className="d-flex justify-content-end align-items-center gap-3 w-100">*/}
                            {/*                        /!*                                            <img className="img-fluid" src={"images/landing/instagram.svg" alt="instagram icon">*!/*/}
                            {/*                        /!*                                            <img className="img-fluid" src={"images/landing/facebook-icon.png"} alt="facebook icon">*!/*/}
                            {/*                        /!*                                            <img className="img-fluid" src={"images/landing/youtube-icon.png"} alt="youtube icon">*!/*/}
                            {/*                        /!*                                            <img className="img-fluid" src={"images/landing/twitter-icon.png"} alt="twitter icon">*!/*/}
                            {/*                        <i data-feather="instagram"></i>*/}
                            {/*                        <i data-feather="facebook"></i>*/}
                            {/*                        <i data-feather="youtube"></i>*/}
                            {/*                        <i data-feather="twitter"></i>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-6 d-flex justify-content-center p-0">*/}
                            {/*                    <a href="/contact-us.html">*/}
                            {/*                        <button type="button"*/}
                            {/*                                className="button-book-consult font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                            Book a*/}
                            {/*                            Consult*/}
                            {/*                        </button>*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-2 d-flex justify-content-center d-md-none">*/}
                            {/*            <button className="navbar-toggler border-none" type="button"*/}
                            {/*                    data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent-2"*/}
                            {/*                    aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                    aria-label="Toggle navigation">*/}
                            {/*                <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</nav>*/}
                            {/*<div className="collapse mt-2" id="navbarToggleExternalContent-2">*/}
                            {/*    <div className="bg-white rounded-5 p-4 d-flex justify-content-between align-content-center gap-2 flex-column">*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/about.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    About*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/service-details.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Services*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/gallery.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Gallery*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center border-2">*/}
                            {/*            <a href="/contact-us.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className="button-book-consult w-100 media-button-bg font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                    Book a Consult*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="d-flex justify-content-around align-items-center pt-4 gap-2">*/}
                            {/*            <i data-feather="instagram"></i>*/}
                            {/*            <i data-feather="facebook"></i>*/}
                            {/*            <i data-feather="youtube"></i>*/}
                            {/*            <i data-feather="twitter"></i>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {/*Hero section content*/}
                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 my-4"></div>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center align-items-center mt-4">
                        <div className="py-4 d-none d-md-block col-12"></div>
                        <div className="py-4 d-none d-md-block col-12"></div>
                        <div className="col-10 col-md-6 mt-4">
                            <div className="">
                                <label
                                    className="font-color-white font-weight-300p font-family-agatho text-center font-size-48 word-wrap">
                                    Your One-Stop Solution For All Your Event Management Needs
                                </label>
                                <div className="pt-2 pt-md-3">
                                    <label
                                        className="font-color-grey-light font-family-manrope text-center align-items-center font-weight-400p font-size-18 media-font-size-14">
                                        Expert planning and seamless management to turn your dream event into a reality
                                        that exceeds expectations without exceeding your budget.
                                    </label>
                                </div>
                            </div>

                            <div className="pt-4"></div>
                            <div className="text-center pt-md-3 ">
                                <Link to={"/contactus"}>
                                    <button
                                        className="button-bg media-button-bg button-contact font-size-18 font-weight-500p font-family-manrope font-color-white">
                                        Contact us
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-4 d-none d-md-block col-12"></div>
            </div>

            {/*our clients section*/}
            <div className="row d-none bg-our-clients-to-gallery pb-4">
                <div className="col-12 d-flex flex-column justify-content-center align-self-center mt-4 ">
                    <div className="pt-4 mt-2 d-none d-md-block"></div>
                    <div className="text-center">
                        <label className="font-color-neutral-black font-weight-500p font-size-42 font-family-agatho">
                            Our Clients
                        </label>
                    </div>
                    <div className="mt-3 pt-4 d-none d-md-block"></div>
                    <div className="pt-3 mt-1 d-block d-md-none"></div>
                    <div className="row our-brands-container pb-4">
                        <div className="col-12">
                            <Swiper
                                className="clients-swiper"
                                speed={1000}
                                loop={true}
                                slidesPerView={4}
                                spaceBetween={20}
                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: true,
                                }}
                                modules={[Autoplay]}
                            >
                                <SwiperSlide>
                                    <img className="img-fluid" src={"images/landing/reevo-client-logo.png"}
                                         alt="reevo"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="img-fluid" src={"images/landing/sewag-client-logo.png"}
                                         alt="sewag"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="img-fluid" src={"images/landing/katio-client-logo.png"}
                                         alt="katio"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="img-fluid" src={"images/landing/wecar-client-logo.png"}
                                         alt="wecar"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="img-fluid" src={"images/landing/sarna-client-logo.png"}
                                         alt="sarna"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="img-fluid" src={"images/landing/yulon-client-logo.png"}
                                         alt="yulon"/>
                                </SwiperSlide>
                            </Swiper>
                            <div className="splide d-none" role="group" aria-label="Splide Basic HTML Example">
                                <div className="splide__track">
                                    <ul className="splide__list">
                                        <li className="splide__slide"><img className="img-fluid"
                                                                           src={"images/landing/reevo-client-logo.png"}
                                                                           alt="reevo"/></li>
                                        <li className="splide__slide"><img className="img-fluid"
                                                                           src={"images/landing/sewag-client-logo.png"}
                                                                           alt="sewag"/></li>
                                        <li className="splide__slide"><img className="img-fluid"
                                                                           src={"images/landing/katio-client-logo.png"}
                                                                           alt="katio"/></li>
                                        <li className="splide__slide"><img className="img-fluid"
                                                                           src={"images/landing/wecar-client-logo.png"}
                                                                           alt="wecar"/></li>
                                        <li className="splide__slide"><img className="img-fluid"
                                                                           src={"images/landing/sarna-client-logo.png"}
                                                                           alt="sarna"/></li>
                                        <li className="splide__slide"><img className="img-fluid"
                                                                           src={"images/landing/yulon-client-logo.png"}
                                                                           alt="yulon"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*our clients execution section*/}

            <div className="row bg-our-clients-to-gallery pt-md-2 px-4 pb-4">
                <div className="col-12 pb-4">
                    <div className="row mb-md-4 mt-md-4 pt-md-4 justify-content-center ">
                        <div className="col-12 pt-2 d-none d-md-block"></div>
                        <div className="col-12 col-md-5 px-0">
                            <div className="client-images-parent">
                                <img className="img-fluid bg-dark-image" src={"images/landing/clients-dark-image.png"}
                                     alt="client dark img"/>
                                <div className="bg-client-image">
                                    <img className="img-fluid w-100 " src={"images/landing/how-we-work-bg-image.png"}
                                         alt={"client image"}/>
                                    <div className="position-absolute bottom-0 p-4">
                                        <label className="text-white font-size-42 font-weight-500p font-family-agatho">How
                                            we Work?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 clients-meeting-to-execution-container">
                            <div className="row bg-white clients-meeting-card-container p-4">
                                <div className="col-12">
                                    <div className="row client-mood-container ">
                                        <div className="col-12 col-md-6 mood-image-container">
                                            <div className="row">
                                                <div className="col-3">
                                                    <img className="img-fluid"
                                                         src={"images/landing/mood-board-gif-image.gif"}
                                                         alt="mood image"/>
                                                </div>
                                                <div className="">
                                                    <label
                                                        className="font-color-linear-gradient font-weight-500p font-family-agatho font-size-32">
                                                        Client meeting
                                                    </label>
                                                    <label
                                                        className="font-color-grey-dark font-family-manrope font-size-14 font-weight-400p">
                                                        Through clear communication via calls or face-to-face
                                                        discussions, we aim to understand your needs and concerns.
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 col-md-6 mood-image-container">
                                            <div className="row pt-3 pt-md-0">
                                                <div className="col-3">
                                                    <img className="img-fluid"
                                                         src={"images/landing/mood-board-gif-image.gif"}
                                                         alt="mood image"/>
                                                </div>
                                                <div className="">
                                                    <label
                                                        className="font-color-linear-gradient font-size-32 font-weight-500p font-family-agatho">Planning</label>
                                                    <label
                                                        className="font-color-grey-dark font-family-manrope font-size-14 font-weight-400p">
                                                        We define the plan of work to create a detailed schedule of all
                                                        activities and events, and set a budget baseline.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pb-4">
                                    <div className="row pt-4 client-mood-container">
                                        <div className="col-12 col-md-6 mood-image-container">
                                            <div className="row">
                                                <div className="col-3">
                                                    <img className="img-fluid"
                                                         src={"images/landing/mood-board-gif-image.gif"}
                                                         alt="mood image"/>
                                                </div>
                                            </div>
                                            <div className="">
                                                <label
                                                    className="font-color-linear-gradient font-size-32 font-weight-500p font-family-agatho">Site
                                                    Visit</label>
                                                <label
                                                    className="font-color-grey-dark font-size-14 font-family-manrope font-weight-400p">
                                                    A site visit allows us to visualize the event space, understand the
                                                    flow of the event, and choose the right design elements.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mood-image-container">
                                            <div className="row pt-3 pt-md-0">
                                                <div className="col-3">
                                                    <img className="img-fluid"
                                                         src={"images/landing/mood-board-gif-image.gif"}
                                                         alt="mood image"/>
                                                </div>
                                            </div>
                                            <div className="">
                                                <label
                                                    className="font-color-linear-gradient font-size-32 font-weight-500p font-family-agatho">
                                                    Execution </label>
                                                <label
                                                    className="font-color-grey-dark font-family-manrope font-size-14 font-weight-400p">
                                                    Execution goes hand-in-hand with planning, ensuring everything goes
                                                    smoothly and on time.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*our services*/}
            <div className="row d-flex flex-column align-items-center pb-4 px-4">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center pt-md-4 mt-md-2 ">
                    <div className="pt-4">
                        <label className="font-family-agatho font-size-42 font-weight-500p">Our Services</label>
                    </div>
                </div>
                <div
                    className="col-12 col-md-10 d-flex flex-column justify-content-center align-items-center mt-md-4 pt-md-3 ">
                    <div className="row">
                        <div className="col-12 py-2 mt-1 d-block d-md-none"></div>
                    </div>
                    <ExploreServicesPage/>
                    <div className={"d-none"}>
                        {/*<div className="row our-services-card-container ">*/}
                        {/*    <div className="col-12">*/}
                        {/*        <div className="row align-items-center">*/}
                        {/*            <div className="col-12 col-md-3 p-3">*/}
                        {/*                <img className="img-fluid rounded-circle"*/}
                        {/*                     src={"images/landing/wedding-square-image.png"} alt="wedding hall"/>*/}
                        {/*            </div>*/}
                        {/*            <div className=" col-12 col-md-8 our-services-card-content px-md-3 py-md-4">*/}
                        {/*                <div className="mb-2">*/}
                        {/*                    <label*/}
                        {/*                        className="font-size-42 font-weight-500p font-color-neutral-black font-family-agatho media-font-size-20 ">Wedding*/}
                        {/*                        & Receptions</label>*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and commitment to each other.*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*                <a href="#">*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-beige-dark font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                        See more*/}
                        {/*                        <i data-feather="arrow-right"></i>*/}
                        {/*                    </label>*/}
                        {/*                </a>*/}
                        {/*                <div className="pt-2 mt-1 d-block d-md-none"></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row our-services-card-container mt-4">*/}
                        {/*    <div className="col-12">*/}
                        {/*        <div className="row align-items-center">*/}
                        {/*            <div className=" col-12 col-md-3 p-3">*/}
                        {/*                <img className="img-fluid rounded-circle"*/}
                        {/*                     src={"images/landing/birthday-square-image.png"} alt="birthday party"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col12 col-md-8 our-services-card-content px-md-3 py-md-4">*/}
                        {/*                <div className="mb-2">*/}
                        {/*                    <label*/}
                        {/*                        className="font-size-42 font-weight-500p font-color-neutral-black font-family-agatho media-font-size-20">Birthday*/}
                        {/*                        Parties</label>*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12">*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and commitment to each other.*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*                <a href="/birthday-services.html">*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-beige-dark font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                        See more*/}
                        {/*                        <i data-feather="arrow-right"></i>*/}
                        {/*                    </label>*/}
                        {/*                </a>*/}
                        {/*                <div className="pt-2 mt-1 d-block d-md-none"></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row our-services-card-container mt-4">*/}
                        {/*    <div className="col-12">*/}
                        {/*        <div className="row align-items-center">*/}
                        {/*            <div className="col-12 col-md-3 p-3">*/}
                        {/*                <img className="img-fluid rounded-circle"*/}
                        {/*                     src={"images/landing/corporate-square-image.png"} alt="corporate events"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-12 col-md-8 our-services-card-content px-md-3 py-md-4">*/}
                        {/*                <div className="mb-2">*/}
                        {/*                    <label*/}
                        {/*                        className="font-size-42 font-weight-500p font-color-neutral-black font-family-agatho media-font-size-20">Corporate*/}
                        {/*                        Events</label>*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12">*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and commitment to each other.*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*                <a href="/corporate-events.html">*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-beige-dark font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                        See more*/}
                        {/*                        <i data-feather="arrow-right"></i>*/}
                        {/*                    </label>*/}
                        {/*                </a>*/}
                        {/*                <div className="pt-2 mt-1 d-block d-md-none"></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row our-services-card-container mt-4">*/}
                        {/*    <div className="col-12">*/}
                        {/*        <div className="row align-items-center">*/}
                        {/*            <div className="col-12 col-md-3 p-3">*/}
                        {/*                <img className="img-fluid rounded-circle"*/}
                        {/*                     src={"images/landing/movie-promotions-square-image.png"}*/}
                        {/*                     alt="movie promotions"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-12 col-md-8 our-services-card-content px-md-3 py-md-4">*/}
                        {/*                <div className="mb-2">*/}
                        {/*                    <label*/}
                        {/*                        className="font-size-42 font-weight-500p font-color-neutral-black font-family-agatho media-font-size-20">Movie*/}
                        {/*                        promotions*/}
                        {/*                    </label>*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12">*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and commitment to each other.*/}
                        {/*                        Weddings mark the start of a new chapter in a couple's life and celebrate*/}
                        {/*                        their love and*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*                <a href="/movie-promotions.html">*/}
                        {/*                    <label*/}
                        {/*                        className="font-color-beige-dark font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                        See more*/}
                        {/*                        <i data-feather="arrow-right"></i>*/}
                        {/*                    </label>*/}
                        {/*                </a>*/}
                        {/*                <div className="pt-2 mt-1 d-block d-md-none"></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="row d-none" id="services-display-container">
                        {/*<div className="col-12">*/}
                        {/*    <div className="row our-services-card-container mt-4">*/}
                        {/*        <div className="col-12">*/}
                        {/*            <div className="row align-items-center">*/}
                        {/*                <div className=" col-12 col-md-3 p-3">*/}
                        {/*                    <img className="img-fluid rounded-circle"*/}
                        {/*                         src={"images/landing/birthday-square-image.png"} alt="birthday party"/>*/}
                        {/*                </div>*/}
                        {/*                <div className="col12 col-md-8 our-services-card-content px-md-3 py-md-4">*/}
                        {/*                    <div className="mb-2">*/}
                        {/*                        <label*/}
                        {/*                            className="font-size-42 font-weight-500p font-color-neutral-black font-family-agatho media-font-size-20">Birthday*/}
                        {/*                            Parties</label>*/}
                        {/*                        <label*/}
                        {/*                            className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12">*/}
                        {/*                            Weddings mark the start of a new chapter in a couple's life and*/}
                        {/*                            celebrate their love and commitment to each other.*/}
                        {/*                            Weddings mark the start of a new chapter in a couple's life and*/}
                        {/*                            celebrate their love and*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                    <a href="/birthday-services.html">*/}
                        {/*                        <label*/}
                        {/*                            className="font-color-beige-dark font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                            See more*/}
                        {/*                            <i data-feather="arrow-right"></i>*/}
                        {/*                        </label>*/}
                        {/*                    </a>*/}
                        {/*                    <div className="pt-2 mt-1 d-block d-md-none"></div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="row our-services-card-container mt-4">*/}
                        {/*        <div className="col-12">*/}
                        {/*            <div className="row align-items-center">*/}
                        {/*                <div className="col-12 col-md-3 p-3">*/}
                        {/*                    <img className="img-fluid rounded-circle"*/}
                        {/*                         src={"images/landing/corporate-square-image.png"}*/}
                        {/*                         alt="corporate events"/>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-12 col-md-8 our-services-card-content px-md-3 py-md-4">*/}
                        {/*                    <div className="mb-2">*/}
                        {/*                        <label*/}
                        {/*                            className="font-size-42 font-weight-500p font-color-neutral-black font-family-agatho media-font-size-20">Corporate*/}
                        {/*                            Events</label>*/}
                        {/*                        <label*/}
                        {/*                            className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12">*/}
                        {/*                            Weddings mark the start of a new chapter in a couple's life and*/}
                        {/*                            celebrate their love and commitment to each other.*/}
                        {/*                            Weddings mark the start of a new chapter in a couple's life and*/}
                        {/*                            celebrate their love and*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                    <a href="/corporate-events.html">*/}
                        {/*                        <label*/}
                        {/*                            className="font-color-beige-dark font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                            See more*/}
                        {/*                            <i data-feather="arrow-right"></i>*/}
                        {/*                        </label>*/}
                        {/*                    </a>*/}
                        {/*                    <div className="pt-2 mt-1 d-block d-md-none"></div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="row our-services-card-container mt-4">*/}
                        {/*        <div className="col-12">*/}
                        {/*            <div className="row align-items-center">*/}
                        {/*                <div className="col-12 col-md-3 p-3">*/}
                        {/*                    <img className="img-fluid rounded-circle"*/}
                        {/*                         src={"images/landing/movie-promotions-square-image.png"}*/}
                        {/*                         alt="movie promotions"/>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-12 col-md-8 our-services-card-content px-md-3 py-md-4">*/}
                        {/*                    <div className="mb-2">*/}
                        {/*                        <label*/}
                        {/*                            className="font-size-42 font-weight-500p font-color-neutral-black font-family-agatho media-font-size-20">Movie*/}
                        {/*                            promotions*/}
                        {/*                        </label>*/}
                        {/*                        <label*/}
                        {/*                            className="font-color-grey-medium font-size-18 font-weight-500p font-family-manrope media-font-size-12">*/}
                        {/*                            Weddings mark the start of a new chapter in a couple's life and*/}
                        {/*                            celebrate their love and commitment to each other.*/}
                        {/*                            Weddings mark the start of a new chapter in a couple's life and*/}
                        {/*                            celebrate their love and*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                    <a href="/movie-promotions.html">*/}
                        {/*                        <label*/}
                        {/*                            className="font-color-beige-dark font-size-18 font-weight-500p font-family-manrope media-font-size-12 ">*/}
                        {/*                            See more*/}
                        {/*                            <i data-feather="arrow-right"></i>*/}
                        {/*                        </label>*/}
                        {/*                    </a>*/}
                        {/*                    <div className="pt-2 mt-1 d-block d-md-none"></div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                {/*button container*/}


            </div>

            {/*our gallery*/}
            <div className="row bg-our-clients-to-gallery d-flex flex-column justify-content-center pb-4">
                <div className="col-12">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                        <div className="col-12 d-flex flex-row justify-content-center align-items-center mt-4">
                            <div className="mt-md-4 pt-md-2">
                                <label className="font-size-42 font-family-agatho font-weight-500p">Our Gallery</label>
                            </div>
                        </div>

                        <div className="col-12 mt-md-4 pt-md-3">
                            <div className="pt-3 mt-1 d-block d-md-none"></div>
                            <div className="row d-flex flex-row justify-content-center align-content-center">
                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <button
                                        className="button-right-left-arrow d-flex justify-content-center align-items-center"
                                        onClick={goGalleryPrev}
                                        id="gallery-left-arrow">
                                        <Icon.ChevronLeft className="text-white "/>
                                    </button>
                                </div>
                                <div className="col-8">
                                    <Swiper
                                        speed={1000}
                                        spaceBetween={20}
                                        slidesPerView={1}
                                        onSwiper={setGallerySwiper}
                                    >
                                        {galleryData.map((each, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="row d-flex flex-row justify-content-center align-content-center"
                                                    key={index}>
                                                    <div className="col-12 col-md-9 br-10">
                                                        <img className="img-fluid preview-image br-10 w-100 h-100"
                                                             src={each[imageGalleryIndex].image_url}
                                                             alt={each[imageGalleryIndex].alt}/>
                                                    </div>
                                                    <div className="col-md-3 d-none d-md-block">
                                                        <div
                                                            className="gallery-images-container d-flex flex-column gap-4 justify-content-center align-items-end">
                                                            <div>
                                                                <img className="img-fluid w-100 br-10 gallery-image"
                                                                     src={each[1].image_url}
                                                                     alt={each[1].alt}/>
                                                            </div>
                                                            <div>
                                                                <img className="img-fluid w-100 br-10 gallery-image"
                                                                     src={each[2].image_url}
                                                                     alt={each[2].alt}/>
                                                            </div>
                                                            <div>
                                                                <img className="img-fluid w-100 br-10 gallery-image"
                                                                     src={each[3].image_url}
                                                                     alt={each[3].alt}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <div className="row d-flex justify-content-center">
                                                            <div className="col-3 br-10">
                                                                <img className="img-fluid br-10 w-100 gallery-image"
                                                                     src={each[4].image_url}
                                                                     alt={each[4].alt}/>
                                                            </div>
                                                            <div className="col-3 br-10">
                                                                <img className="img-fluid br-10 w-100 gallery-image"
                                                                     src={each[5].image_url}
                                                                     alt={each[5].alt}/>
                                                            </div>
                                                            <div className="col-3 br-10">
                                                                <img className="img-fluid br-10 w-100 gallery-image"
                                                                     src={each[6].image_url}
                                                                     alt={each[6].alt}/>
                                                            </div>
                                                            <div className="col-3 br-10">
                                                                <img className="img-fluid br-10 w-100 gallery-image"
                                                                     src={each[7].image_url}
                                                                     alt={each[7].alt}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <div className="col-2 d-flex flex-column justify-content-center align-items-center ">
                                    <button
                                        className="button-right-left-arrow d-flex justify-content-center align-items-center"
                                        onClick={goGalleryNext}
                                        id="gallery-right-arrow">
                                        <Icon.ChevronRight className="text-white "/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 mt-1 d-block d-md-none"></div>
                        <div className="my-md-4 pt-md-3 d-flex justify-content-center align-content-center">
                            <Link to={"/gallery"}>
                                <button
                                    className="button-bg font-weight-500p font-size-18 font-color-white font-family-manrope">Access
                                    complete gallery
                                </button>
                            </Link>
                        </div>
                        <div className="mt-md-2"></div>
                    </div>
                </div>

            </div>

            {/*our clients reviews*/}
            <div className="row our-clients-review-bg-container justify-content-center pb-4">
                <div className="col-12 pb-4">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="row pt-md-4 mt-md-2">
                                <div className="col-8 col-md-4 px-4 pt-4">
                                    <label
                                        className="font-size-42 font-weight-500p font-family-agatho font-color-neutral-black">
                                        What Our Clients Say About Us
                                    </label>
                                </div>
                                <div className="col-12 pb-md-4 mt-md-4 pt-3">
                                    <div className="mt-1 d-block d-md-none"></div>
                                    <div className="d-flex justify-content-between">
                                        <Link to={"/contactus"}>
                                            <button
                                                className="button-bg media-button-bg font-size-18 font-weight-500p font-family-manrope font-color-white">
                                                Contact us
                                            </button>
                                        </Link>
                                        <div className="d-flex justify-content-between px-2">
                                            <button onClick={goReviewPrev}
                                                    className="button-right-left-arrow mx-4 d-flex justify-content-center align-items-center"
                                                    id="review-left-arrow">
                                                <Icon.ChevronLeft className="text-white "/>
                                            </button>
                                            <button onClick={goReviewNext}
                                                    className="button-right-left-arrow d-flex align-items-center justify-content-center"
                                                    id="review-right-arrow">
                                                <Icon.ChevronRight className="text-white "/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*splide section*/}

                    <div className="row">
                        <div className="col-12 pt-4 pt-md-3">
                            <Swiper
                                className="review-swiper"
                                slidesPerView={2}
                                loop={true}
                                spaceBetween={20}
                                autoplay={true}
                                modules={[Autoplay]}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 2
                                    },
                                    400: {
                                        slidesPerView: 1
                                    }
                                }}
                                onSwiper={setReviewSwiper}
                            >
                                <SwiperSlide>
                                    <div className="review-card-container p-4">
                                        <div>
                                            <label
                                                className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                A big thanks to the Le_Aira team, our office annual day was a huge
                                                success. How you can extract 250 people from lunch and back into the
                                                auditorium in 5 minutes is a mystery to me – but very much appreciated.
                                                I honestly don’t know how we could possibly do it without you. Your team
                                                really did an amazing job of pulling off an incredibly successful event.
                                            </label>
                                        </div>
                                        <div
                                            className="review-card-footer-section mt-2 d-flex gap-2 align-items-center">
                                            <img src={"images/landing/mukesh-image.png"} alt="mukesh image"/>
                                            <div>
                                                <label
                                                    className="font-size-14 font-weight-500p font-family-manrope font-color-grey-medium">
                                                    Mukesh
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className=" d-flex flex-column review-card-container p-4">
                                        <div>
                                            <label
                                                className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                Thank you so much for all your wonderful efforts to make my wedding
                                                night such a success, everyone has commented how much they loved the
                                                party, the food, the music, and how amazing it all looked. Everyone said
                                                it was like being in a movie, which I think says it all.
                                            </label>
                                        </div>
                                        <div
                                            className="review-card-footer-section mt-2 d-flex align-items-center gap-2">
                                            <img src={"images/landing/harish-kumar-image.png"}
                                                 alt="harish kumar image"/>
                                            <div>
                                                <label
                                                    className="font-size-14 font-weight-500p font-color-grey-medium font-family-manrope">Harish
                                                    Kumar</label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className=" p-4 review-card-container">
                                        <div>
                                            <label
                                                className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                A big thank you to you and your staff for making my daughter’s birthday
                                                party the success it was!! Everyone was very impressed with the props,
                                                the food was amazing and the venue was spectacular! We will definitely
                                                keep you in mind for future upcoming events.
                                            </label>
                                        </div>
                                        <div
                                            className="review-card-footer-section mt-2 d-flex align-items-center gap-2">
                                            <img src={"images/landing/riya-kannan-image.png"} alt="riya kannan image"/>
                                            <div>
                                                <label
                                                    className="font-size-14 font-weight-500p font-color-grey-medium font-family-manrope">
                                                    Riya
                                                    Kiran</label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className=" d-flex flex-column review-card-container p-4">
                                        <div>
                                            <label
                                                className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                I hired ‘Le-Aira-Even’ for my family party. I found the whole team to be
                                                extremely professional, but more importantly they were very cooperative.
                                                The food was amazing, it was beautifully presented, the staff were on
                                                time, professionally presented and friendly and I have no hesitation in
                                                recommending their services. Thank You Le-Aira-Events team for a job
                                                well done.
                                            </label>
                                        </div>
                                        <div
                                            className="review-card-footer-section mt-2 d-flex align-items-center gap-2">
                                            <img src={"images/landing/harish-kumar-image.png"}
                                                 alt="harish kumar image"/>
                                            <div>
                                                <label className="font-size-14 font-weight-500p font-color-grey-medium font-family-manrope">
                                                    Rakesh
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className=" p-4 review-card-container">
                                        <div>
                                            <label
                                                className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                In one line I give their service a 5-star rating. excellent planning,
                                                perfect execution, on time and so easy to deal with.
                                            </label>
                                        </div>
                                        <div
                                            className="review-card-footer-section mt-2 d-flex align-items-center gap-2">
                                            <img src={"images/landing/riya-kannan-image.png"} alt="riya kannan image"/>
                                            <div>
                                                <label className="font-size-14 font-weight-500p font-color-grey-medium font-family-manrope">
                                                    Lipsa
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="review-card-container p-4">
                                        <div>
                                            <label
                                                className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                Just wanted to say a huge thank you for everything the Le-Aira team did
                                                to make our first movie promotion event in Hyderabad run so well.
                                                Everything turned out perfectly from start to finish. We are truly
                                                satisfied with the service that I received from them. Not to mention
                                                that the price was cost-effective too.
                                            </label>
                                        </div>
                                        <div
                                            className="review-card-footer-section mt-2 d-flex gap-2 align-items-center">
                                            <img src={"images/landing/mukesh-image.png"} alt="mukesh image"/>
                                            <div>
                                                <label
                                                    className="font-size-14 font-weight-500p font-family-manrope font-color-grey-medium">
                                                    Naveen
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className=" d-flex flex-column review-card-container p-4">
                                        <div>
                                            <label
                                                className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                Thank you Le-Aira-Events for the services provided to us by the team.
                                                I’m really happy that I hired an expert team of event managers for my
                                                parent’s wedding anniversary.The team did everything to make the
                                                experience enjoyable. At the end of the event, so many of our guests
                                                came up to me and said it was a brilliant evening; they hadn’t had so
                                                much fun like this in ages! The food, the staff, the decoration
                                                everything was perfect. I'd totally recommend their services for any
                                                kind of family party, whether it's big or small.
                                            </label>
                                        </div>
                                        <div
                                            className="review-card-footer-section mt-2 d-flex align-items-center gap-2">
                                            <img src={"images/landing/harish-kumar-image.png"}
                                                 alt="harish kumar image"/>
                                            <div>
                                                <label className="font-size-14 font-weight-500p font-color-grey-medium font-family-manrope">
                                                    Sai Kumar
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <div className="splide d-none" id="review-splide" role="group"
                                 aria-label="Splide Basic HTML Example">
                                <div className="splide__track">
                                    <ul className="splide__list">
                                        <li className="splide__slide">
                                            <div className="">
                                                <div className="review-card-container p-4">
                                                    <div>
                                                        <label
                                                            className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                            A big thanks to the Le_Aira team, our office annual day was
                                                            a huge success. How you can extract 250 people from lunch
                                                            and back into the auditorium in 5 minutes is a mystery to me
                                                            – but very much appreciated. I honestly don’t know how we
                                                            could possibly do it without you. Your team really did an
                                                            amazing job of pulling off an incredibly successful event.
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="review-card-footer-section mt-2 d-flex gap-2 align-items-center">
                                                        <img src={"images/landing/mukesh-image.png"}
                                                             alt="mukesh image"/>
                                                        <div>
                                                            <label
                                                                className="font-size-14 font-weight-500p font-family-manrope font-color-grey-medium">
                                                                Mukesh
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                        <li className="splide__slide">
                                            <div className="">
                                                <div className=" d-flex flex-column review-card-container p-4">
                                                    <div>
                                                        <label
                                                            className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                            Outstanding customer support! They went above and beyond to
                                                            help me resolve my issue.
                                                            I felt valued as a customer, and their commitment to
                                                            ensuring my satisfaction left a
                                                            lasting impression.
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="review-card-footer-section mt-2 d-flex align-items-center gap-2">
                                                        <img src={"images/landing/harish-kumar-image.png"}
                                                             alt="harish kumar image"/>
                                                        <div>
                                                            <label
                                                                className="font-size-14 font-weight-500p font-color-grey-medium font-family-manrope">Harish
                                                                Kumar</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="splide__slide ">
                                            <div className="">
                                                <div className=" p-4 review-card-container">
                                                    <div>
                                                        <label
                                                            className="font-weight-500p font-family-manrope font-size-18 font-color-grey-dark">
                                                            Efficient and professional. I will definitely use their
                                                            services again.
                                                            The professionalism of this team made the entire process
                                                            effortless and stress-free.
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="review-card-footer-section mt-2 d-flex align-items-center gap-2">
                                                        <img src={"images/landing/riya-kannan-image.png"}
                                                             alt="riya kannan image"/>
                                                        <div>
                                                            <label
                                                                className="font-size-14 font-weight-500p font-color-grey-medium font-family-manrope">
                                                                Riya
                                                                Kiran</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-12 d-flex justify-content-center mt-4 pt-md-2 ">
                    <div className="bg-your-one-stop-solution-container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <label
                                        className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                        One-Stop
                                        Solution For All Your Event Management Needs</label>
                                    <div className="pt-3">
                                        <label className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">
                                            Your dream event is just one step away from making you say 'wow.'
                                        </label>
                                    </div>
                                </div>
                                <div className="pt-4 mt-3 text-center">
                                    <Link to={"/contactus"}>
                                        <button
                                            className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                            Contact us
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className=" pb-4 pt-2 mb-4 d-none d-md-block"></div>
            </div>

            {/*footer section*/}
            <Footer/>
        </div>
    )
}

export default HomePage
