import Footer from "../Footer/footer";
import NavbarPage from "../Navbar/navbarPage";
import * as Icon from 'react-feather';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';

import servicesData from '../Models/exploreEachServices.json'
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const Gallery = () => {

    const [activeTab, setActiveTab] = useState(servicesData[0].service_slug);
    const [activeTabsData, setActiveTabsData] = useState([]);
    const [galleryTabSwiper, setGalleryTabsSwiper] = useState(null);

    const goGalleryTabNext = () => {
        if (galleryTabSwiper !== null) {
            galleryTabSwiper.slideNext();
        }
    }

    const goGalleryTabPrev = () => {
        if (galleryTabSwiper !== null) {
            galleryTabSwiper.slidePrev();
        }
    }

    useEffect(() => {
        const data = servicesData.find(each => each.service_slug === activeTab);
        setActiveTabsData(data? [data] : []);
        console.log("gallery", [data][0].gallery);
    }, [activeTab]);

    return (
        <div className="container-fluid">

            {/*Hero section*/}
            <div className="row bg-gallery-hero-section">
                <div className="col-12">
                    {/*Nav bar*/}
                    <div className="row justify-content-center mt-4 pt-3 fixed-top">
                        <NavbarPage />
                        <div className="col-11 justify-content-center col-md-10">
                            {/*<nav className="navbar navbar-danger bg-white nav-bar-container d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="row d-flex justify-content-between">*/}
                            {/*        <div className="col-2 font-color-white d-flex justify-content-center align-items-center d-block d-md-none p-0">*/}
                            {/*            <a href="/index.html">*/}
                            {/*                /!*                                <label class="font-color-grey-dark font-size-42 font-family-agatho">Logo</label>*!/*/}
                            {/*                <img className="img-fluid w-100" src="/images/landing/le-aira-event-logo.png" alt="logo" />*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="col-3 d-none d-md-flex flex-row justify-content-center align-content-center font-family-manrope">*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/about.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/service-details.html">*/}
                            {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Services*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="d-flex flex-column justify-content-center">*/}
                            {/*                <a href="/gallery.html">*/}
                            {/*                    <button type="button"*/}
                            {/*                            className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                        Gallery*/}
                            {/*                    </button>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-2 font-color-white d-none d-md-block px-4">*/}
                            {/*            <div className="px-2">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid" src="/images/landing/le-aira-event-logo.png" alt="logo" />*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-4 p-0 d-none d-md-flex flex-column justify-content-center">*/}
                            {/*            <div className="row d-flex align-items-center justify-content-between d-md-flex">*/}
                            {/*                <div className="col-6 p-0">*/}
                            {/*                    <div className="d-flex justify-content-end align-items-center gap-3 w-100">*/}
                            {/*                        <i data-feather="instagram"></i>*/}
                            {/*                        <i data-feather="facebook"></i>*/}
                            {/*                        <i data-feather="youtube"></i>*/}
                            {/*                        <i data-feather="twitter"></i>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-6 d-flex justify-content-center p-0">*/}
                            {/*                    <a href="/contact-us.html">*/}
                            {/*                        <button type="button"*/}
                            {/*                                className="button-book-consult font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                            Book a*/}
                            {/*                            Consult*/}
                            {/*                        </button>*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="col-2 d-flex justify-content-center d-md-none">*/}
                            {/*            <button className="navbar-toggler border-none" type="button"*/}
                            {/*                    data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent-2"*/}
                            {/*                    aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                    aria-label="Toggle navigation">*/}
                            {/*                <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</nav>*/}
                            {/*<div className="collapse mt-2" id="navbarToggleExternalContent-2">*/}
                            {/*    <div className="bg-white rounded-5 p-4  d-flex justify-content-between align-content-center gap-2 flex-column">*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/about.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    About*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/service-details.html">*/}
                            {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Services*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center">*/}
                            {/*            <a href="/gallery.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                            {/*                    Gallery*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-center border-2">*/}
                            {/*            <a href="/contact-us.html">*/}
                            {/*                <button type="button"*/}
                            {/*                        className="button-book-consult w-100 media-button-bg font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                            {/*                    Book a Consult*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="d-flex justify-content-around align-items-center pt-4 gap-2">*/}
                            {/*            <i data-feather="instagram"></i>*/}
                            {/*            <i data-feather="facebook"></i>*/}
                            {/*            <i data-feather="youtube"></i>*/}
                            {/*            <i data-feather="twitter"></i>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*Hero section content*/}
                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 my-4"></div>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center align-items-center mt-0 mb-4 pb-2 pb-md-4 mt-md-4">
                        <div className="col-12 py-md-4 py-3"></div>
                        {/*                    <div class="py-4 col-12"></div>*/}
                        <div className="col-12 mt-md-4">
                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                <div className="col-12 col-md-8">
                                    <div className="d-flex flex-column justify-content-center align-items-center mt-md-2 mt-md-4">
                                        <label className="font-color-white font-weight-300p font-family-agatho text-center font-size-48">
                                            Moments into photos
                                        </label>
                                        <div className="px-4">
                                            <label className="font-color-background-grey font-family-manrope pb-2 text-center align-items-center font-weight-500p font-size-18">
                                                Whether it's small events, or grand occasions, be ready to celebrate exclusive events and create priceless memories. Celebrate with delight, everything will be just right as we are here to turn your dream into a real event and capture those precious moments forever.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pt-4 my-2"></div>
                                <div className="col-12 col-md-10 pb-md-2">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-10 bg-gallery-services-container d-flex flex-row justify-content-between align-items-center py-4">
                                            <div className="text-center d-none d-md-block">
                                                <button type="button" onClick={goGalleryTabPrev} className="bg-transparent border-0 " id="gallery-tab-left-arrow">
                                                    <Icon.ChevronLeft className="text-white"/>
                                                </button>
                                            </div>
                                            <Swiper
                                                className="gallery-swiper-tabs"
                                                loop={true}
                                                slidesPerView={4}
                                                spaceBetween={5}
                                                breakpoints={{
                                                    400 : {
                                                        slidesPerView: 2,
                                                        spaceBetween: 5
                                                    },

                                                    768 : {
                                                        slidesPerView: 3,
                                                        spaceBetween:5
                                                    }
                                                }}
                                                onSwiper={setGalleryTabsSwiper}
                                            >
                                                {servicesData.map(eachTab => (
                                                    <SwiperSlide className={"d-flex justify-content-center align-items-center"} key={eachTab.id}>
                                                        <button className={`font-size-18 border-none font-weight-500p font-family-manrope font-color-black ${activeTab === eachTab.service_slug ? "btn-service-active-tab": "button-gallery-bg"} `} id={eachTab.id} type="button" onClick={()=> setActiveTab(eachTab.service_slug)}>{eachTab.service_name}</button>
                                                    </SwiperSlide>
                                                ))}
                                                {/*<SwiperSlide className={"d-none justify-content-center"}>*/}
                                                {/*    <button className="nav-link font-size-18 font-color-black" id="wedding-tab" data-bs-toggle="pill" data-bs-target="#wedding" type="button" role="tab" aria-controls="wedding" aria-selected="true">Wedding and Receptions</button>*/}
                                                {/*</SwiperSlide>*/}
                                                {/*<SwiperSlide className={"d-none justify-content-center"}>*/}
                                                {/*    <button className="nav-link font-size-18 font-color-black" id="birthday-tab" data-bs-toggle="pill" data-bs-target="#birthday" type="button" role="tab" aria-controls="birthday" aria-selected="fasle">Birthday Parties</button>*/}
                                                {/*</SwiperSlide>*/}
                                                {/*<SwiperSlide  className={"d-none justify-content-center"}>*/}
                                                {/*    <button className="nav-link font-size-18 font-color-black" id="corporate-tab" data-bs-toggle="pill" data-bs-target="#corporate" type="button" role="tab" aria-controls="corporate" aria-selected="false">Corporate Events</button>*/}
                                                {/*</SwiperSlide>*/}
                                                {/*<SwiperSlide  className={"d-none justify-content-center"}>*/}
                                                {/*    <button className="nav-link font-size-18 font-color-black" id="movie-tab" data-bs-toggle="pill" data-bs-target="#movie" type="button" role="tab" aria-controls="movie" aria-selected="false">Movie promotions</button>*/}
                                                {/*</SwiperSlide>*/}
                                                {/*<SwiperSlide className={"d-none justify-content-center"}>*/}
                                                {/*    <button className="nav-link font-size-18 font-color-black" id="public-tab" data-bs-toggle="pill" data-bs-target="#public" type="button" role="tab" aria-controls="public" aria-selected="false">Public Gathering</button>*/}
                                                {/*</SwiperSlide>*/}
                                            </Swiper>
                                            <div className="splide justify-content-center d-none" role="group" aria-label="Splide Basic HTML Example" id="gallery-nav-tabs">
                                                <div className="splide__track nav nav-tabs" id="nav-tab" role="tablist">
                                                    <ul className="splide__list ">
                                                        <li className="splide__slide nav-item " role="presentation">
                                                            <button className=" nav-link active font-size-18 font-weight-500p font-family-manrope font-color-grey-2" id="wedding-gallery-tab" data-bs-toggle="tab" data-bs-target="#weddingGallery" type="button" role="tab" aria-controls="weddingGallery" aria-selected="true">Wedding and Receptions</button>
                                                        </li>
                                                        <li className="splide__slide nav-item" role="presentation">
                                                            <button className="nav-link font-size-18 font-weight-500p font-family-manrope font-color-grey-2 " id="birthday-gallery-tab" data-bs-toggle="tab" data-bs-target="#birthdayGallery" type="button" role="tab" aria-controls="birthdayGallery" aria-selected="false">Birthday parties</button>
                                                        </li>
                                                        <li className="splide__slide nav-item" role="presentation">
                                                            <button className="nav-link font-size-18 font-weight-500p font-family-manrope font-color-grey-2" id="corporate-gallery-tab" data-bs-toggle="tab" data-bs-target="#corporateGallery" type="button" role="tab" aria-controls="corporateGallery" aria-selected="false">Corporate events</button>
                                                        </li>
                                                        <li className="splide__slide nav-item" role="presentation">
                                                            <button className="nav-link font-size-18 font-weight-500p font-family-manrope font-color-grey-2" id="movie-gallery-tab" data-bs-toggle="tab" data-bs-target="#movieGallery" type="button" role="tab" aria-controls="movieGallery" aria-selected="false">Movie promotions</button>
                                                        </li>
                                                        <li className="splide__slide nav-item" role="presentation">
                                                            <button className="nav-link font-size-18 font-weight-500p font-family-manrope font-color-grey-2" id="public-gallery-tab" data-bs-toggle="tab" data-bs-target="#publicGallery" type="button" role="tab" aria-controls="publicGallery" aria-selected="false">Public gathering</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text-center d-none d-md-block ">
                                                <button className="bg-transparent border-0" onClick={goGalleryTabNext} type="button" id="gallery-tab-right-arrow">
                                                    <Icon.ChevronRight className="text-white"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/*Gallery section*/}
            <div className="row bg-gallery-container">
                <div className="col-12">

                    {activeTabsData?.map((each) =>
                        <div className="row" key={each.id}>
                            <div className="col-12 d-flex flex-column wedding-gallery-container mt-md-4 px-4 py-4">
                                <div className="row">
                                    <div className="col-12 col-md-7 ">
                                        <img className="img-fluid w-100 br-10 h-100" src={each.gallery.first_row[0].image_url} alt={each.gallery.first_row[0].alt}/>
                                    </div>
                                    <div className="col-12 col-md-5 ">
                                        <div className="row h-100 d-flex flex-column gap-2 gap-md-4">
                                            <div className="col-12 px-md-0">
                                                <img className="img-fluid pt-2 br-10 pt-md-0 w-100" src={each.gallery.first_row[1].image_url} alt={each.gallery.first_row[1].alt}/>
                                            </div>
                                            <div className="col-12 px-md-0">
                                                <img className="img-fluid w-100 br-10" src={each.gallery.first_row[2].image_url} alt={each.gallery.first_row[2].alt}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-2 py-md-4">
                                    <div className="col-12 col-md-7 ">
                                        <div className="row d-flex flex-column h-100 gap-2 gap-md-4 ">
                                            <div className="col-12">
                                                <img className="img-fluid br-10 w-100 h-100 object-fit-cover" src={each.gallery.second_row[0].image_url} alt={each.gallery.second_row[0].alt}/>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-7 pe-md-0">
                                                        <img className="img-fluid br-10 w-100 h-100 object-fit-cover" src={each.gallery.second_row[1].image_url} alt={each.gallery.second_row[1].alt}/>
                                                    </div>
                                                    <div className="col-12 col-md-5 py-2 py-md-0">
                                                        <img className="img-fluid br-10 w-100 h-100 object-fit-cover" src={each.gallery.second_row[2].image_url} alt={each.gallery.second_row[2].alt}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 ps-md-0">
                                        <img className="img-fluid br-10 w-100 h-100 object-fit-fill" src={each.gallery.second_row[3].image_url} alt={each.gallery.second_row[3].alt}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <img className="img-fluid br-10 w-100" src={each.gallery.third_row[0].image_url} alt={each.gallery.third_row[0].alt}/>
                                    </div>
                                </div>
                                <div className="row py-2 py-md-4">
                                    {each.gallery.fourth_row.map((eachImage, index) => (
                                        <div className="col-12 col-md-4" key={index}>
                                            <img className="img-fluid mb-2 mb-md-0 br-10 w-100" src={eachImage.image_url} alt={eachImage.alt} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row bg-gallery-container">
                        <div className="col-12 d-flex justify-content-center my-md-2">
                            <div className="bg-your-one-stop-solution-container">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10">
                                        <div className="d-flex flex-column justify-content-center align-items-center" >
                                            <label className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                                one-stop
                                                solution for all your event management needs</label>
                                            <div className="pt-3">
                                                <label className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">
                                                    Your dream event is just one step away from making you say 'wow.'
                                                </label>
                                            </div>
                                        </div>
                                        <div className="pt-4 mt-3 text-center">
                                            <Link to={"/contactus"}>
                                                <button className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                                    Contact us
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {activeTabsData?.map((each) =>
                        <div className="row" key={each.id}>
                            <div className="col-12 d-flex flex-column wedding-gallery-container mt-md-4 px-4 py-4">
                                <div className="row py-2 py-md-4">
                                    <div className="col-12 col-md-7 order-1">
                                        <div className="row d-flex flex-column h-100 gap-2 gap-md-4 pt-2 pt-md-0">
                                            <div className="col-12">
                                                <img className="img-fluid br-10 w-100 h-100 object-fit-cover" src={each.gallery.second_row[0].image_url} alt={each.gallery.second_row[0].alt}/>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-7 pe-md-0">
                                                        <img className="img-fluid br-10 w-100 h-100 object-fit-cover" src={each.gallery.second_row[1].image_url} alt={each.gallery.second_row[1].alt}/>
                                                    </div>
                                                    <div className="col-12 col-md-5 pt-2 pt-md-0">
                                                        <img className="img-fluid br-10 w-100 h-100 object-fit-cover" src={each.gallery.second_row[2].image_url} alt={each.gallery.second_row[2].alt}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 ps-md-0">
                                        <img className="img-fluid br-10 w-100 h-100 object-fit-fill" src={each.gallery.second_row[3].image_url} alt={each.gallery.second_row[3].alt}/>
                                    </div>
                                </div>
                                <div className="row py-2 py-md-4">
                                    {each.gallery.fourth_row.map((eachImage, index) => (
                                        <div className="col-12 col-md-4" key={index}>
                                            <img className="img-fluid mb-2 mb-md-0 br-10 w-100" src={eachImage.image_url} alt={eachImage.alt} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="tab-content d-none" id="myTabContent">
                        <div className="tab-pane fade show active" id="weddingGallery" role="tabpanel" aria-labelledby="weeding-gallery-tab">
                            <div className="row">
                                <div className="col-12 d-flex flex-column wedding-gallery-container mt-md-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2">
                                        <div>
                                            <img className="img-fluid w-100 image-radius" src="/images/gallery/gallery-bride-groom-sitting-image.png"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start align-items-start gap-2 gap-md-4">
                                            <img className="img-fluid pt-2 pt-md-0 w-100" src="/images/gallery/gallery-wedding-reception-couple-image.png"/>
                                                <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-touch-foot-image.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2 py-2 py-md-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100 py-2 py-md-0" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center px-2">
                                        <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-white-clothes.png"/>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>

                            {/*our one-stop solution*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex justify-content-center my-md-2">
                                    <div className="bg-your-one-stop-solution-container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <div className="d-flex flex-column justify-content-center align-items-center" >
                                                    <label
                                                        className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                                        one-stop
                                                        solution for all your event management needs</label>
                                                    <div className="pt-3">
                                                        <label className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">Lorem
                                                            ipsum dolor sit
                                                            amet consectetur. In magnis scelerisque egestas dictum laoreet.</label>
                                                    </div>
                                                </div>
                                                <div className="pt-4 mt-3 text-center">
                                                    <a href="/contact-us.html">
                                                        <button className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                                            Contact us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Gallery section 2*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex flex-column mt-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row-reverse justify-content-center align-content-start gap-2 gap-md-4 px-2 py-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100 pb-2 pb-md-0" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="pt-2 pt-md-0">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="birthdayGallery" role="tabpanel" aria-labelledby="birthday-gallery-tab">
                            <div className="row">
                                <div className="col-12 d-flex flex-column wedding-gallery-container mt-md-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2">
                                        <div>
                                            <img className="img-fluid w-100 image-radius" src="/images/gallery/gallery-bride-groom-sitting-image.png"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start align-items-start gap-2 gap-md-4">
                                            <img className="img-fluid pt-2 pt-md-0 w-100" src="/images/gallery/gallery-wedding-reception-couple-image.png"/>
                                                <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-touch-foot-image.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2 py-2 py-md-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100 py-2 py-md-0" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center px-2">
                                        <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-white-clothes.png"/>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>

                            {/*our one-stop solution*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex justify-content-center my-md-2">
                                    <div className="bg-your-one-stop-solution-container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <div className="d-flex flex-column justify-content-center align-items-center" >
                                                    <label
                                                        className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                                        one-stop
                                                        solution for all your event management needs</label>
                                                    <div className="pt-3">
                                                        <label className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">Lorem
                                                            ipsum dolor sit
                                                            amet consectetur. In magnis scelerisque egestas dictum laoreet.</label>
                                                    </div>
                                                </div>
                                                <div className="pt-4 mt-3 text-center">
                                                    <a href="/contact-us.html">
                                                        <button className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                                            Contact us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Gallery section 2*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex flex-column mt-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row-reverse justify-content-center align-content-start gap-2 gap-md-4 px-2 py-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100 pb-2 pb-md-0" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="pt-2 pt-md-0">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="corporateGallery" role="tabpanel" aria-labelledby="corporate-gallery-tab">
                            <div className="row">
                                <div className="col-12 d-flex flex-column wedding-gallery-container mt-md-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2">
                                        <div>
                                            <img className="img-fluid w-100 image-radius" src="/images/gallery/gallery-bride-groom-sitting-image.png"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start align-items-start gap-2 gap-md-4">
                                            <img className="img-fluid pt-2 pt-md-0 w-100" src="/images/gallery/gallery-wedding-reception-couple-image.png"/>
                                                <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-touch-foot-image.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2 py-2 py-md-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100 py-2 py-md-0" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center px-2">
                                        <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-white-clothes.png"/>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>

                            {/*our one-stop solution*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex justify-content-center my-md-2">
                                    <div className="bg-your-one-stop-solution-container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <div className="d-flex flex-column justify-content-center align-items-center" >
                                                    <label
                                                        className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                                        one-stop
                                                        solution for all your event management needs</label>
                                                    <div className="pt-3">
                                                        <label className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">Lorem
                                                            ipsum dolor sit
                                                            amet consectetur. In magnis scelerisque egestas dictum laoreet.</label>
                                                    </div>
                                                </div>
                                                <div className="pt-4 mt-3 text-center">
                                                    <a href="/contact-us.html">
                                                        <button className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                                            Contact us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Gallery section 2*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex flex-column mt-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row-reverse justify-content-center align-content-start gap-2 gap-md-4 px-2 py-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100 pb-2 pb-md-0" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="pt-2 pt-md-0">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="movieGallery" role="tabpanel" aria-labelledby="movie-gallery-tab">
                            <div className="row">
                                <div className="col-12 d-flex flex-column wedding-gallery-container mt-md-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2">
                                        <div>
                                            <img className="img-fluid w-100 image-radius" src="/images/gallery/gallery-bride-groom-sitting-image.png"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start align-items-start gap-2 gap-md-4">
                                            <img className="img-fluid pt-2 pt-md-0 w-100" src="/images/gallery/gallery-wedding-reception-couple-image.png"/>
                                                <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-touch-foot-image.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2 py-2 py-md-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100 py-2 py-md-0" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center px-2">
                                        <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-white-clothes.png"/>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>

                            {/*our one-stop solution*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex justify-content-center my-md-2">
                                    <div className="bg-your-one-stop-solution-container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <div className="d-flex flex-column justify-content-center align-items-center" >
                                                    <label
                                                        className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                                        one-stop
                                                        solution for all your event management needs</label>
                                                    <div className="pt-3">
                                                        <label className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">Lorem
                                                            ipsum dolor sit
                                                            amet consectetur. In magnis scelerisque egestas dictum laoreet.</label>
                                                    </div>
                                                </div>
                                                <div className="pt-4 mt-3 text-center">
                                                    <a href="/contact-us.html">
                                                        <button className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                                            Contact us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Gallery section 2*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex flex-column mt-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row-reverse justify-content-center align-content-start gap-2 gap-md-4 px-2 py-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100 pb-2 pb-md-0" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="pt-2 pt-md-0">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="publicGallery" role="tabpanel" aria-labelledby="public-gallery-tab">
                            <div className="row">
                                <div className="col-12 d-flex flex-column wedding-gallery-container mt-md-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2">
                                        <div>
                                            <img className="img-fluid w-100 image-radius" src="/images/gallery/gallery-bride-groom-sitting-image.png"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start align-items-start gap-2 gap-md-4">
                                            <img className="img-fluid pt-2 pt-md-0 w-100" src="/images/gallery/gallery-wedding-reception-couple-image.png"/>
                                                <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-touch-foot-image.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex flex-row justify-content-center align-content-start gap-2 gap-md-4 px-2 py-2 py-md-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100 py-2 py-md-0" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center px-2">
                                        <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-white-clothes.png"/>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>

                            {/*our one-stop solution*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex justify-content-center my-md-2">
                                    <div className="bg-your-one-stop-solution-container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <div className="d-flex flex-column justify-content-center align-items-center" >
                                                    <label
                                                        className="font-size-42 media-font-size-24 font-color-neutral-black text-center font-family-agatho font-weight-500p">Your
                                                        one-stop
                                                        solution for all your event management needs</label>
                                                    <div className="pt-3">
                                                        <label className="font-color-grey-new media-font-size-16 font-family-manrope font-weight-400p text-center font-size-18">Lorem
                                                            ipsum dolor sit
                                                            amet consectetur. In magnis scelerisque egestas dictum laoreet.</label>
                                                    </div>
                                                </div>
                                                <div className="pt-4 mt-3 text-center">
                                                    <a href="/contact-us.html">
                                                        <button className="button-bg media-button-bg media-font-size-16 font-size-18 font-weight-500p font-family-manrope font-color-white">
                                                            Contact us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Gallery section 2*/}
                            <div className="row bg-gallery-container">
                                <div className="col-12 d-flex flex-column mt-4 px-2 py-4">
                                    <div className="d-block d-md-flex flex-row-reverse justify-content-center align-content-start gap-2 gap-md-4 px-2 py-4">
                                        <div className="d-flex flex-column gap-2 gap-md-4">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-bride-groom-image-with-face-to-face.png"/>
                                                <div className="d-block d-md-flex flex-row justify-content-start align-items-start gap-2">
                                                    <img className="img-fluid w-100 pb-2 pb-md-0" src="/images/gallery/gallery-bride-groom-clothes-tied-image.png"/>
                                                        <img className="img-fluid w-100" src="/images/gallery/gallery-bride-image-with-gold-bg.png"/>
                                                </div>
                                        </div>
                                        <div className="pt-2 pt-md-0">
                                            <img className="img-fluid w-100" src="/images/gallery/gallery-groom-bride-image-with-white-red-clothes.png"/>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-center align-items-center gap-2 px-2 py-2 py-md-4">
                                        <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-side-pose-red-dress.png"/>
                                            <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-prayer-image-red-dress.png"/>
                                                <img className="img-fluid mb-2 mb-md-0 w-100" src="/images/gallery/gallery-bride-image-closed-eyes.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Footer section*/}

            <Footer/>
        </div>
    )
}

export default Gallery
