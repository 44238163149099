// import logo from './logo.svg';
import './App.css';
import HomePage from "./components/HomePage/homePage";
import "bootstrap/dist/css/bootstrap.css";
import './components/styles/scss/main.scss'
import {Routes, Route, BrowserRouter} from "react-router-dom";
import ServiceDetails from "./components/ServiceDetailsPage/serviceDetails";
import ContactUs from "./components/ContactUsPage/contactUs";
import AboutUs from "./components/AboutUsPage/aboutUs";
import Gallery from "./components/GalleryPage/gallery";

function App() {
  return (
    <div className="">
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<HomePage/>} />
                <Route path={"/services/:id"} element={<ServiceDetails/>} />
                <Route path={"/contactus"} element={<ContactUs/>} />
                <Route path={"/about"} element={<AboutUs/>} />
                <Route path={"/gallery"} element={<Gallery/>} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
