import NavbarPage from "../Navbar/navbarPage";
import Footer from "../Footer/footer";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css'
import * as Icon from 'react-feather'
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import servicesData from '../Models/exploreEachServices.json'
import {useState, useEffect, useRef} from "react";

const ServiceDetails = () => {

    const params = useParams();
    const id = params.id;

    const [serviceData, setServiceData] = useState([]);
    const [tabsData, setActiveTabsData] = useState([]);
    const [activeTab, setActiveTab] = useState(id);
    const [provideSwiper, setProvideSwiper] = useState(null);
    const [tabsSwiper, setTabsSwiper] = useState(null);
    const [imageIndex, setImageIndex] = useState(0);

    const goProvidesPrev = () => {
        if (provideSwiper !== null) {
            provideSwiper.slidePrev()
        }
    }

    const goProvidesNext = () => {
        if (provideSwiper !== null) {
            provideSwiper.slideNext()
        }
    }

    const goTabsPrev = () => {
        if (tabsSwiper !== null) {
            tabsSwiper.slidePrev()
        }
    }
    const goTabsNext = () => {
        if (tabsSwiper !== null) {
            tabsSwiper.slideNext()
        }
    }

    useEffect(() => {
        const data = servicesData.find(each => each.service_slug === id);
        setServiceData(data ? [data] : []);
        setActiveTabsData(data ? [data] : [])
        console.log("service details", [data]);
    },[id])

    useEffect(() => {
        const tabs = servicesData.find(each => each.service_slug === activeTab);
        setActiveTabsData(tabs ? [tabs]: []);
        // console.log("services gallery", tabs.service_gallery);
    }, [activeTab]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Increment the image index
            console.log("image index", imageIndex)
            // console.log("length",tabsData[imageIndex]?.service_gallery?.first_image?.length)
            setImageIndex((prevIndex) => (prevIndex + 1) % tabsData[0]?.service_gallery?.first_image?.length);
        }, 1000); // Change image every second (1000 milliseconds)
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [imageIndex, tabsData[0]?.service_gallery?.first_image?.length]);

    return(
        <div className="container-fluid">

            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {/*Navbar section*/}
                            <div className="row justify-content-center mt-4 pt-3 fixed-top">
                                <NavbarPage/>
                                <div className="col-11 justify-content-center col-md-10">
                                    {/*<nav className="navbar navbar-danger bg-white nav-bar-container d-flex justify-content-between align-items-center px-4">*/}
                                    {/*    <div className="row d-flex justify-content-between">*/}
                                    {/*        <div className="col-2 font-color-white d-flex justify-content-center align-items-center d-block d-md-none p-0">*/}
                                    {/*            <a href="/index.html">*/}
                                    {/*                {/*                                <label className="font-color-grey-dark font-size-42 font-family-agatho">Logo</label>*/}
                                    {/*                <img className="img-fluid w-100" src="images/landing/le-aira-event-logo.png" alt="logo" />*/}
                                    {/*            </a>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-3 d-none d-md-flex flex-row justify-content-center align-content-center font-family-manrope">*/}
                                    {/*            <div className="d-flex flex-column justify-content-center">*/}
                                    {/*                <a href="/about.html">*/}
                                    {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                                    {/*                        About*/}
                                    {/*                    </button>*/}
                                    {/*                </a>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="d-flex flex-column justify-content-center">*/}
                                    {/*                <a href="/service-details.html">*/}
                                    {/*                    <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                                    {/*                        Services*/}
                                    {/*                    </button>*/}
                                    {/*                </a>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="d-flex flex-column justify-content-center">*/}
                                    {/*                <a href="/gallery.html">*/}
                                    {/*                    <button type="button"*/}
                                    {/*                            className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                                    {/*                        Gallery*/}
                                    {/*                    </button>*/}
                                    {/*                </a>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*        <div className="col-2 font-color-white d-none d-md-block px-4">*/}
                                    {/*            <div className="px-2">*/}
                                    {/*                <a href="/index.html">*/}
                                    {/*                    <img className="img-fluid" src="images/landing/le-aira-event-logo.png" alt="logo" />*/}
                                    {/*                </a>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*        <div className="col-4 p-0 d-none d-md-flex flex-column justify-content-center">*/}
                                    {/*            <div className="row d-flex align-items-center justify-content-between d-md-flex">*/}
                                    {/*                <div className="col-6 p-0">*/}
                                    {/*                    <div className="d-flex justify-content-end align-items-center gap-3 w-100">*/}
                                    {/*                        <i data-feather="instagram"></i>*/}
                                    {/*                        <i data-feather="facebook"></i>*/}
                                    {/*                        <i data-feather="youtube"></i>*/}
                                    {/*                        <i data-feather="twitter"></i>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="col-6 d-flex justify-content-center p-0">*/}
                                    {/*                    <a href="/contact-us.html">*/}
                                    {/*                        <button type="button"*/}
                                    {/*                                className="button-book-consult font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                                    {/*                            Book a*/}
                                    {/*                            Consult*/}
                                    {/*                        </button>*/}
                                    {/*                    </a>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*        <div className="col-2 d-flex justify-content-center d-md-none">*/}
                                    {/*            <button className="navbar-toggler border-none" type="button"*/}
                                    {/*                    data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent-2"*/}
                                    {/*                    aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                                    {/*                    aria-label="Toggle navigation">*/}
                                    {/*                <span className="navbar-toggler-icon text-indgo"></span>*/}
                                    {/*            </button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*</nav>*/}
                                    {/*<div className="collapse mt-2" id="navbarToggleExternalContent-2">*/}
                                    {/*    <div className="bg-white rounded-5 p-4  d-flex justify-content-between align-content-center gap-2 flex-column">*/}
                                    {/*        <div className="text-center">*/}
                                    {/*            <a href="/about.html">*/}
                                    {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                                    {/*                    About*/}
                                    {/*                </button>*/}
                                    {/*            </a>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="text-center">*/}
                                    {/*            <a href="/service-details.html">*/}
                                    {/*                <button type="button" className="btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                                    {/*                    Services*/}
                                    {/*                </button>*/}
                                    {/*            </a>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="text-center">*/}
                                    {/*            <a href="/gallery.html">*/}
                                    {/*                <button type="button"*/}
                                    {/*                        className=" btn btn-light bg-transparent font-color-grey-dark font-size-18 font-weight-500p border-none">*/}
                                    {/*                    Gallery*/}
                                    {/*                </button>*/}
                                    {/*            </a>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="text-center border-2">*/}
                                    {/*            <a href="/contact-us.html">*/}
                                    {/*                <button type="button"*/}
                                    {/*                        className="button-book-consult w-100 media-button-bg font-size-14 font-family-manrope font-weight-500p font-color-beige-light border-none">*/}
                                    {/*                    Book a Consult*/}
                                    {/*                </button>*/}
                                    {/*            </a>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="d-flex justify-content-around align-items-center pt-4 gap-2">*/}
                                    {/*            <i data-feather="instagram"></i>*/}
                                    {/*            <i data-feather="facebook"></i>*/}
                                    {/*            <i data-feather="youtube"></i>*/}
                                    {/*            <i data-feather="twitter"></i>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*hero section*/}

                    {serviceData.map(each => (
                        <div className="row d-none d-md-block bg-hero-service-container pb-4" key={each.id} style={{backgroundImage: `url('/images/services/bg-image-services-hero-section-blue-flash.png'), url(${each.service_background_image_url})`}}>
                            <div className="col-12">
                                {/*hero section*/}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="py-4 my-4"></div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 my-4 d-none d-md-block"></div>
                                    <div className="col-12 pt-2 d-block d-md-none"></div>
                                    <div className="col-12 col-md-10 d-flex flex-row justify-content-center align-items-center pt-4">
                                        <div className="row d-flex flex-row justify-content-between align-items-center">
                                            <div className="col-12 col-md-6 text-align-center">
                                                <div className="">
                                                    <label className="font-size-48 font-color-white media-font-size-32 font-weight-300p font-family-agatho">
                                                        {each.service_name}
                                                    </label>
                                                </div>
                                                <div className="mb-md-4">
                                                    <label className="font-size-18 font-weight-400p font-family-manrope font-color-grey-light">
                                                        {each.service_description}
                                                    </label>
                                                </div>

                                                <div className=" pt-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button" className="button-bg media-button-bg media-font-size-16 font-color-white font-weight-500p font-size-18 font-family-manrope">Book with us</button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-6 res-display-none">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <img className="img-fluid service-image-radius" src={each.service_display_image_url} alt={each.alt}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-4 px-4 d-none d-md-block"></div>
                                    <div className="row my-4 px-4 d-none d-md-block" ></div>
                                    <div className="pt-2 d-block d-md-none"></div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {serviceData.map(each => (
                        <div className="row d-block d-md-none bg-hero-service-container pb-4" key={each.id} style={{backgroundImage: `url(${each.service_background_image_url})`}}>
                            <div className="col-12">
                                {/*hero section*/}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="py-4 my-4"></div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 my-4 d-none d-md-block"></div>
                                    <div className="col-12 pt-2 d-block d-md-none"></div>
                                    <div className="col-12 col-md-10 d-flex flex-row justify-content-center align-items-center pt-4">
                                        <div className="row d-flex flex-row justify-content-between align-items-center">
                                            <div className="col-12 col-md-6 text-align-center">
                                                <div className="">
                                                    <label className="font-size-48 font-color-white media-font-size-32 font-weight-300p font-family-agatho">
                                                        {each.service_name}
                                                    </label>
                                                </div>
                                                <div className="mb-md-4">
                                                    <label className="font-size-18 font-weight-400p font-family-manrope font-color-grey-light">
                                                        {each.service_description}
                                                    </label>
                                                </div>

                                                <div className=" pt-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button" className="button-bg media-button-bg media-font-size-16 font-color-white font-weight-500p font-size-18 font-family-manrope">Book with us</button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-6 res-display-none">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <img className="img-fluid service-image-radius" src={each.service_display_image_url} alt={each.alt}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-4 px-4 d-none d-md-block"></div>
                                    <div className="row my-4 px-4 d-none d-md-block" ></div>
                                    <div className="pt-2 d-block d-md-none"></div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="row bg-hero-service-container d-none pb-4">
                        <div className="col-12">
                            {/*hero section*/}
                            <div className="row">
                                <div className="col-12">
                                    <div className="py-4 my-4"></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 my-4 d-none d-md-block"></div>
                                <div className="col-12 pt-2 d-block d-md-none"></div>
                                <div className="col-12 col-md-10 d-flex flex-row justify-content-center align-items-center pt-4">
                                    <div className="row d-flex flex-row justify-content-between align-items-center">
                                        <div className="col-12 col-md-6 text-align-center">
                                            <div className="">
                                                <label className="font-size-48 font-color-white media-font-size-32 font-weight-300p font-family-agatho">
                                                    Wedding and Receptions
                                                </label>
                                            </div>
                                            <div className="mb-md-4">
                                                <label className="font-size-18 font-weight-400p font-family-manrope font-color-grey-light">
                                                    Weddings mark the start of a new chapter in a couple's life and celebrate their love and commitment to each other.
                                                    Weddings mark the start of a new chapter in a couple's life and celebrate their love.
                                                </label>
                                            </div>

                                            <div className=" pt-3">
                                                <Link to={"/contactus"}>
                                                    <button type="button" className="button-bg media-button-bg media-font-size-16 font-color-white font-weight-500p font-size-18 font-family-manrope">Book with us</button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 res-display-none">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img className="img-fluid rounded-circle" src={"/images/services/service-wedding-image.png"} alt="wedding-image"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-4 px-4 d-none d-md-block"></div>
                                <div className="row my-4 px-4 d-none d-md-block" ></div>
                                <div className="pt-2 d-block d-md-none"></div>
                            </div>
                        </div>

                    </div>

                    {/* services-container*/}
                    <div className="row bg-our-services-container d-flex justify-content-center">
                        <div className="col-12 col-md-9 bg-white bg-services-container bg-service-container py-4 d-flex justify-content-around align-items-center">
                            <div className="text-center d-none d-md-block">
                                <button type="button" onClick={goTabsPrev} className="bg-transparent border-none " id="nav-tabs-prev-button">
                                    <Icon.ChevronLeft className="font-color-beige-new-darker"/>
                                </button>
                            </div>
                            <Swiper
                                className="services-swiper-tabs"
                                loop={true}
                                slidesPerView={4}
                                spaceBetween={0}
                                breakpoints={{
                                    300 : {
                                        slidesPerView: 1,
                                        spaceBetween: 10
                                    },

                                    768 : {
                                        slidesPerView: 3
                                    }
                                }}
                                onSwiper={setTabsSwiper}
                            >
                                {servicesData.map(eachTab => (
                                    <SwiperSlide className={"d-flex justify-content-center align-items-center"} key={eachTab.id}>
                                        <button className={`font-size-18 border-none font-weight-500p font-family-manrope font-color-black ${activeTab === eachTab.service_slug ? "btn-service-active-tab": "btn-service-tab bg-transparent"} `} id={eachTab.id} type="button" onClick={()=> setActiveTab(eachTab.service_slug)}>{eachTab.service_name}</button>
                                    </SwiperSlide>
                                ))}
                                {/*<SwiperSlide className={"d-none justify-content-center"}>*/}
                                {/*    <button className="nav-link font-size-18 font-color-black" id="wedding-tab" data-bs-toggle="pill" data-bs-target="#wedding" type="button" role="tab" aria-controls="wedding" aria-selected="true">Wedding and Receptions</button>*/}
                                {/*</SwiperSlide>*/}
                                {/*<SwiperSlide className={"d-none justify-content-center"}>*/}
                                {/*    <button className="nav-link font-size-18 font-color-black" id="birthday-tab" data-bs-toggle="pill" data-bs-target="#birthday" type="button" role="tab" aria-controls="birthday" aria-selected="fasle">Birthday Parties</button>*/}
                                {/*</SwiperSlide>*/}
                                {/*<SwiperSlide  className={"d-none justify-content-center"}>*/}
                                {/*    <button className="nav-link font-size-18 font-color-black" id="corporate-tab" data-bs-toggle="pill" data-bs-target="#corporate" type="button" role="tab" aria-controls="corporate" aria-selected="false">Corporate Events</button>*/}
                                {/*</SwiperSlide>*/}
                                {/*<SwiperSlide  className={"d-none justify-content-center"}>*/}
                                {/*    <button className="nav-link font-size-18 font-color-black" id="movie-tab" data-bs-toggle="pill" data-bs-target="#movie" type="button" role="tab" aria-controls="movie" aria-selected="false">Movie promotions</button>*/}
                                {/*</SwiperSlide>*/}
                                {/*<SwiperSlide className={"d-none justify-content-center"}>*/}
                                {/*    <button className="nav-link font-size-18 font-color-black" id="public-tab" data-bs-toggle="pill" data-bs-target="#public" type="button" role="tab" aria-controls="public" aria-selected="false">Public Gathering</button>*/}
                                {/*</SwiperSlide>*/}
                            </Swiper>
                            <div className="splide d-none" role="group" aria-label="Splide Basic HTML Example" id="nav-tabs">
                                <div className="splide__track nav nav-pills" id="pills-tab" role="tablist">
                                    <ul className="splide__list">
                                        <li className="splide__slide nav-item" role="presentation">
                                            <button className="nav-link active font-size-18 font-color-black" id="wedding-tab-" data-bs-toggle="pill" data-bs-target="#wedding" type="button" role="tab" aria-controls="wedding" aria-selected="true">Wedding and Receptions</button>
                                        </li>
                                        <li className="splide__slide nav-item" role="presentation">
                                            <button className="nav-link  font-size-18 font-color-black" id="birthday-tab-" data-bs-toggle="pill" data-bs-target="#birthday" type="button" role="tab" aria-controls="birthday" aria-selected="true">Birthday parties</button>
                                        </li>
                                        <li className="splide__slide nav-item" role="presentation">
                                            <button className="nav-link font-size-18 font-color-black" id="corporate-tab-" data-bs-toggle="pill" data-bs-target="#corporate" type="button" role="tab" aria-controls="corporate" aria-selected="false">Corporate events</button>

                                        </li>
                                        <li className="splide__slide nav-item" role="presentation">
                                            <button className="nav-link font-size-18 font-color-black" id="movie-tab-" data-bs-toggle="pill" data-bs-target="#movie" type="button" role="tab" aria-controls="movie" aria-selected="false">Movie promotions</button>
                                        </li>
                                        <li className="splide__slide nav-item" role="presentation">
                                            <button className="nav-link font-size-18 font-color-black" id="public-tab-" data-bs-toggle="pill" data-bs-target="#public" type="button" role="tab" aria-controls="public" aria-selected="false">Public gathering</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className=" text-center d-none d-md-block">
                                <button type="button" onClick={goTabsNext} className="border-none bg-transparent" id="nav-tabs-next-button">
                                    <Icon.ChevronRight className="font-color-beige-new-darker"/>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/*what we provide*/}
                    <div className="row bg-our-services-container pt-4">
                        <div className="col-12 pb-md-4 mb-md-4">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="pt-md-4 mt-md-2"></div>
                                    <div className="">
                                        <label className="font-size-42 font-family-agatho font-weight-500p">What We Provide</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 align-items-center">
                                    <div className="col-12 pt-md-4 mt-md-4"></div>

                                    <div className="row justify-content-center">
                                        <div className="col-md-1 d-none d-md-flex flex-column justify-content-center align-items-center">
                                            <button type="button" onClick={goProvidesPrev} className="button-services-left-right p-3 rounded-circle" id="wedding-services-left-arrow">
                                                <Icon.ChevronLeft className="font-color-beige-new-darker"/>
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-9 p-md-0">
                                            <Swiper
                                                className="provider-swiper h-100 py-3"
                                                slidesPerView={3}
                                                autoHeight={true}
                                                spaceBetween={10}
                                                loop={true}
                                                breakpoints={{
                                                    768: {
                                                        slidesPerView: 3
                                                    },
                                                    300: {
                                                        slidesPerView: 1
                                                    }
                                                }}
                                                onSwiper={setProvideSwiper}
                                            >
                                                {tabsData.map((each) => (
                                                    each.what_we_provide.map((eachProvider) => (
                                                        <SwiperSlide className="" key={eachProvider.provide_id}>
                                                            {/* Card Flip */}
                                                            <div className="card-flip ">
                                                                <div className="flip h-100">
                                                                    <div className="front">
                                                                        {/* front content */}
                                                                        <div className="card br-10 d-flex  flex-column justify-content-center provider-bg-image " style={{backgroundImage: `url(${eachProvider.provide_image_url})`}}>
                                                                            <div className="w-100 text-center px-4" data-holder-rendered="true">
                                                                                <label className="font-size-42 font-weight-500p font-color-new-beige-lightest font-family-agatho">{eachProvider.provide_name}</label>
                                                                            </div>
                                                                            {/*<img className="img-fluid br-10" src={eachProvider.provide_image_url} alt={eachProvider.provide_image_alt} data-holder-rendered="true"/>*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className="back">
                                                                        {/* back content */}
                                                                        <div className="card  provider-flip-bg-image overflow-y-scroll br-10">
                                                                            <div className="w-100 p-4 text-center" data-holder-rendered="true">
                                                                                <label className="font-color-new-beige-lighter font-size-18 font-weight-500p font-family-manrope">{eachProvider.provide_description}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* End Card Flip */}
                                                        </SwiperSlide>
                                                    ))
                                                ))}
                                            </Swiper>
                                            <div className="splide__track d-none">
                                                <ul className="splide__list">
                                                    <li className="splide__slide">
                                                        <div className="row">
                                                            <div className="col-10 col-md-10 px-0">
                                                                {/* Card Flip */}
                                                                <div className="card-flip">
                                                                    <div className="flip">
                                                                        <div className="front">
                                                                            {/* front content */}
                                                                            <div className="card">
                                                                                <img className="img-fluid" src={"/images/services/hair-makeup-and-saree-draping-image.png"} alt="image" data-holder-rendered="true"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="back">
                                                                            {/* back content */}
                                                                            <div className="card">
                                                                                <img className="img-fluid" src={"/images/services/hair-makeup-flipped-image.png"} alt="Image" data-holder-rendered="true"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* End Card Flip */}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="splide__slide">
                                                        <div className="row">
                                                            <div className="col-10 col-md-10 px-0">
                                                                {/* Card Flip */}
                                                                <div className="card-flip">
                                                                    <div className="flip">
                                                                        <div className="front">
                                                                            {/* front content */}
                                                                            <div className="card">
                                                                                <img className="card-img-top" src="images/services/venue-decor-image.png" alt="venue decor" data-holder-rendered="true"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="back">
                                                                            {/* back content */}
                                                                            <div className="card">
                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* End Card Flip */}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="splide__slide">
                                                        <div className="row">
                                                            <div className="col-10 col-md-10 px-0">
                                                                {/* Card Flip */}
                                                                <div className="card-flip ">
                                                                    <div className="flip">
                                                                        <div className="front">
                                                                            {/* front content */}
                                                                            <div className="card">
                                                                                <img className="card-img-top" src="images/services/catering-image.png" alt=" catering"  data-holder-rendered="true"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="back">
                                                                            {/* back content */}
                                                                            <div className="card">
                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image " data-holder-rendered="true"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* End Card Flip */}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-1 d-none d-md-flex  d-flex flex-column justify-content-center align-items-center">
                                            <button type="button" onClick={goProvidesNext} className="button-services-left-right p-3 rounded-circle" id="wedding-services-right-arrow">
                                                <Icon.ChevronRight className="font-color-beige-new-darker"/>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-11 col-md-9 bg-gallery-show-container d-flex flex-row justify-content-center mt-4">
                                            {tabsData.map((each) => (
                                                <div className="d-flex flex-row justify-content-between gallery-show-container gap-2 p-2" key={each.id}>
                                                    <div className="d-flex flex-column justify-content-end align-items-start">
                                                        <img className="img-fluid w-100 br-10" src={each?.service_gallery?.first_image[imageIndex]?.image_url} alt={each.service_gallery?.first_image[imageIndex]?.alt}/>
                                                    </div>
                                                    <div className=" d-flex flex-column justify-content-between gap-2">
                                                        <img className="img-fluid w-100 br-10" src={each?.service_gallery?.second_image[imageIndex]?.image_url} alt={each.service_gallery?.second_image[imageIndex]?.alt}/>
                                                        <img className="img-fluid w-100 br-10" src={each?.service_gallery?.third_image[imageIndex]?.image_url} alt={each.service_gallery?.third_image[imageIndex]?.alt}/>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between gap-2">
                                                        <img className="img-fluid w-100 br-10" src={each?.service_gallery?.fourth_image[imageIndex]?.image_url} alt={each.service_gallery?.fourth_image[imageIndex]?.alt}/>
                                                        <img className="img-fluid w-100 br-10" src={each?.service_gallery?.fifth_image[imageIndex]?.image_url} alt={each.service_gallery?.fifth_image[imageIndex]?.alt}/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="tab-content d-none" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="wedding" role="tabpanel" aria-labelledby="wedding-tab">
                                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                                <div className="pt-2 mt-1 d-block d-md-none"></div>
                                                <div className="col-12 py-2 wedding-receptions-container">
                                                    <div className="row d-flex justify-content-center gap-2">
                                                        <div className="col-md-1 d-none d-md-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="wedding-services-left-arrow">
                                                                <i data-feather="chevron-left" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                        <div className="splide col-12 col-md-9 justify-content-center" role="group" aria-label="Splide Basic HTML Example" id="wedding-services-splide">
                                                            <div className="splide__track">
                                                                <ul className="splide__list">
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src={"/images/services/hair-makeup-and-saree-draping-image.png"} alt="image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src={"/images/services/hair-makeup-flipped-image.png"} alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/venue-decor-image.png" alt="venue decor" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip ">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/catering-image.png" alt=" catering"  data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image " data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 d-none d-md-flex  d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="wedding-services-right-arrow">
                                                                <i data-feather="chevron-right" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-md-4 mt-md-2"></div>
                                                <div className="col-11 col-md-9 bg-gallery-show-container d-flex flex-row justify-content-center mt-4">
                                                    <div className="d-flex flex-row justify-content-between gallery-show-container gap-2 p-2">
                                                        <div className="d-flex flex-column justify-content-end align-items-start"  >
                                                            <img className="img-fluid couple-images w-100" id="couple-photos" src="images/services/couple-image-with-wedding-dress.png" alt="couple image with wedding dress"/>
                                                            <img className="img-fluid couple-images d-none" src="images/services/couple-image-side-pose-with-flower-mala.png" alt="couple image side pose with flower mala"/>
                                                            <img className="img-fluid couple-images d-none" src="images/services/couple-imge-background-wood-door.png" alt="couple image background wood door"/>
                                                            <img className="img-fluid couple-images d-none" src="images/services/bride-sit-with-red-saree-groom-stand-image.png" alt="bride sit with red saree groom stand image"/>
                                                            <img className="img-fluid couple-images d-none" src="images/services/couple-image-with-green-and-white-dress.png" alt="couple image with green and white dress"/>
                                                        </div>
                                                        <div className=" d-flex flex-column justify-content-between gap-2">
                                                            <img className="img-fluid w-100" id="bride-groom-hands-image" src="images/services/couple-promise-hands-image.png" alt="couple promise hands image"/>
                                                            <img className="img-fluid w-100 rings-exchange-images d-none" src="images/services/bride-groom-exchange-rings.png" alt="bride groom exchange rings"/>
                                                            <img className="img-fluid w-100 rings-exchange-images d-none" src="images/services/groom-hand-image.png" alt="groom hand image"/>
                                                            <img className="img-fluid w-100" id="bride-groom-image" src="images/services/bride-groom-pose-image-simingpool.png" alt="bride groom pose image swimming pool"/>
                                                            <img className="img-fluid w-100 bride-groom-images d-none" src="images/services/couple-image-at-house.png" alt="couple image at house"/>
                                                            <img className="img-fluid w-100 bride-groom-images d-none" src="images/services/couple-image-with-red-white-dress.png" alt="couple image with red white dress"/>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-between gap-2">
                                                            <img className="img-fluid w-100" id="haldhi-images" src="images/services/groom-haldhi-image.png" alt="bride halidhi"/>
                                                            <img className="img-fluid w-100 bride-groom-marriage d-none" src="images/services/couple-image-at-ring-game.png" alt="couple image at ring game"/>
                                                            <img className="img-fluid w-100 bride-groom-marriage d-none" src="images/services/couple-image-at-rice-pouring.png" alt="couple image at rice pouring"/>
                                                            <img className="img-fluid w-100" id="bride-red-dress" src="images/services/bride-image-with-red-dress.png" alt="bride image with red dress"/>
                                                            <img className="img-fluid w-100 groom-red-dress d-none" src="images/services/couple-hands-pinky-promise.png" alt="couple hands pinky promise"/>
                                                            <img className="img-fluid w-100 groom-red-dress d-none" src="images/services/couple-marriage-image.png" alt="couple marriage image"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="birthday" role="tabpanel" aria-labelledby="birthday-tab">
                                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                                <div className='col-12 py-4 birthday-parties-container'>
                                                    <div className="row d-flex justify-content-center align-items-center gap-2 px-2">
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="birthday-services-left-arrow">
                                                                <i data-feather="chevron-left" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                        <div className="splide col-12 col-md-9 justify-content-center" role="group" aria-label="Splide Basic HTML Example" id="birthday-services-splide">
                                                            <div className="splide__track">
                                                                <ul className="splide__list">
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-and-saree-draping-image.png" alt="image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/venue-decor-image.png" alt="venue decor" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip ">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/catering-image.png" alt=" catering"  data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image " data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="birthday-services-right-arrow">
                                                                <i data-feather="chevron-right" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    {/*                                    <button type="button" className="button-services-left-right text-center">*/}
                                                    {/*                                        <img className="img-fluid" src="images/services/services-left-arrow-icon.png" alt="left arrow icon">*/}
                                                    {/*                                    </button>*/}
                                                    {/*                                    <button type="button" className="button-services-left-right">*/}
                                                    {/*                                        <img className="img-fluid" src="images/services/services-right-arrow-icon.png" alt="right arrow">*/}
                                                    {/*                                    </button>*/}
                                                </div>
                                                <div className="col-12 col-md-9 bg-gallery-show-container d-flex justify-content-center mt-4">
                                                    <div className="d-flex flex-row justify-content-between gallery-show-container gap-4 py-2">
                                                        <div className="d-flex flex-column justify-content-end align-items-start" >
                                                            <img className="img-fluid couple-images w-100" id="birthday-gallery" src="images/services/couple-image-with-wedding-dress.png" alt="couple image with wedding dress"/>
                                                            <img className="img-fluid couple-images d-none birthday-gallery-image" src="images/services/couple-image-side-pose-with-flower-mala.png" alt="couple image side pose with flower mala"/>
                                                            <img className="img-fluid couple-images d-none birthday-gallery-image" src="images/services/couple-imge-background-wood-door.png" alt="couple image background wood door"/>
                                                            <img className="img-fluid couple-images d-none birthday-gallery-image" src="images/services/bride-sit-with-red-saree-groom-stand-image.png" alt="bride sit with red saree groom stand image"/>
                                                            <img className="img-fluid couple-images d-none birthday-gallery-image" src="images/services/couple-image-with-green-and-white-dress.png" alt="couple image with green and white dress"/>
                                                        </div>
                                                        <div className=" d-flex flex-column justify-content-between gap-4">
                                                            <img className="img-fluid" id="birthday-rings-exchange-preview" src="images/services/couple-promise-hands-image.png" alt="couple promise hands image"/>
                                                            <img className="img-fluid w-100 birthday-rings-exchange-images d-none" src="images/services/bride-groom-exchange-rings.png" alt="bride groom exchange rings image"/>
                                                            <img className="img-fluid w-100 birthday-rings-exchange-images d-none" src="images/services/groom-hand-image.png" alt="groom hand image"/>
                                                            <img className="img-fluid" id="birthday-bride-groom-pose-image-swimming-pool" src="images/services/bride-groom-pose-image-simingpool.png" alt="bride groom pose image swimming pool"/>
                                                            <img className="img-fluid w-100 birthday-bride-groom-images d-none" src="images/services/couple-image-at-house.png" alt="couple image at house"/>
                                                            <img className="img-fluid w-100 birthday-bride-groom-images d-none" src="images/services/couple-image-with-red-white-dress.png" alt="couple image with red white dress"/>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-between gap-2">
                                                            <img className="img-fluid" id="birthday-groom-haldi-preview-image" src="images/services/groom-haldhi-image.png" alt="groom haldhi image"/>
                                                            <img className="img-fluid w-100 birthday-haldi-images d-none" src="images/services/couple-image-at-ring-game.png" alt="couple image at ring game"/>
                                                            <img className="img-fluid w-100 birthday-haldi-images d-none" src="images/services/couple-image-at-rice-pouring.png" alt="couple image at rice pouring"/>
                                                            <img className="img-fluid" id="birthday-bride-preview-image" src="images/services/bride-image-with-red-dress.png" alt="bride image with red dress"/>
                                                            <img className="img-fluid w-100 birthday-groom-red-dress d-none" src="images/services/couple-hands-pinky-promise.png" alt="couple hands pinky promise"/>
                                                            <img className="img-fluid w-100 birthday-groom-red-dress d-none" src="images/services/couple-marriage-image.png" alt="couple marriage image"/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="corporate" role="tabpanel" aria-labelledby="corporate-tab">
                                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                                <div className="col-12 py-4 corporate-events-container" >
                                                    <div className="row d-flex justify-content-center align-items-center gap-2 px-2">
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="corporate-services-left-arrow">
                                                                <i data-feather="chevron-left" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                        <div className="splide col-12 col-md-9 justify-content-center" role="group" aria-label="Splide Basic HTML Example" id="corporate-services-splide">
                                                            <div className="splide__track">
                                                                <ul className="splide__list">
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-and-saree-draping-image.png" alt="image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/venue-decor-image.png" alt="venue decor" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip ">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/catering-image.png" alt=" catering"  data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image " data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="corporate-services-right-arrow">
                                                                <i data-feather="chevron-right" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-9 bg-gallery-show-container d-flex flex-row justify-content-center mt-4">
                                                    <div className="d-flex flex-row justify-content-between gallery-show-container gap-4 p-2">
                                                        <div className="d-flex flex-column justify-content-end align-items-start" >
                                                            <img className="img-fluid couple-images w-100" id="corporate-gallery" src="images/services/couple-image-with-wedding-dress.png" alt="couple image with wedding dress"/>
                                                            <img className="img-fluid couple-images d-none corporate-gallery-image" src="images/services/couple-image-side-pose-with-flower-mala.png" alt="couple image side pose with flower mala"/>
                                                            <img className="img-fluid couple-images d-none corporate-gallery-image" src="images/services/couple-imge-background-wood-door.png" alt="couple image background wood door"/>
                                                            <img className="img-fluid couple-images d-none corporate-gallery-image" src="images/services/bride-sit-with-red-saree-groom-stand-image.png" alt="bride sit with red saree groom stand image"/>
                                                            <img className="img-fluid couple-images d-none corporate-gallery-image" src="images/services/couple-image-with-green-and-white-dress.png" alt="couple image with green and white dress"/>
                                                        </div>
                                                        <div className=" d-flex flex-column justify-content-between gap-4">
                                                            <img className="img-fluid" id="corporate-rings-exchange-preview" src="images/services/couple-promise-hands-image.png" alt="couple promise hands image"/>
                                                            <img className="img-fluid w-100 corporate-rings-exchange-images d-none" src="images/services/bride-groom-exchange-rings.png" alt="bride groom exchange rings image"/>
                                                            <img className="img-fluid w-100 corporate-rings-exchange-images d-none" src="images/services/groom-hand-image.png" alt="groom hand image"/>
                                                            <img className="img-fluid" id="corporate-bride-groom-pose-image-swimming-pool" src="images/services/bride-groom-pose-image-simingpool.png" alt="bride groom pose image swimming pool"/>
                                                            <img className="img-fluid w-100 corporate-bride-groom-images d-none" src="images/services/couple-image-at-house.png" alt="couple image at house"/>
                                                            <img className="img-fluid w-100 corporate-bride-groom-images d-none" src="images/services/couple-image-with-red-white-dress.png" alt="couple image with red white dress"/>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-between gap-2">
                                                            <img className="img-fluid" id="corporate-groom-haldi-preview-image" src="images/services/groom-haldhi-image.png" alt="groom haldhi image"/>
                                                            <img className="img-fluid w-100 corporate-haldi-images d-none" src="images/services/couple-image-at-ring-game.png" alt="couple image at ring game"/>
                                                            <img className="img-fluid w-100 corporate-haldi-images d-none" src="images/services/couple-image-at-rice-pouring.png" alt="couple image at rice pouring"/>
                                                            <img className="img-fluid" id="corporate-bride-preview-image" src="images/services/bride-image-with-red-dress.png" alt="bride image with red dress"/>
                                                            <img className="img-fluid w-100 corporate-groom-red-dress d-none" src="images/services/couple-hands-pinky-promise.png" alt="couple hands pinky promise"/>
                                                            <img className="img-fluid w-100 corporate-groom-red-dress d-none" src="images/services/couple-marriage-image.png" alt="couple marriage image"/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="movie" role="tabpanel" aria-labelledby="movie-tab">
                                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                                <div className="col-12 py-4 movie-promotions-container" >
                                                    <div className="row d-flex justify-content-center align-items-center gap-2 px-2">
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="movie-services-left-arrow">
                                                                <i data-feather="chevron-left" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                        <div className="splide col-12 col-md-9 justify-content-center" role="group" aria-label="Splide Basic HTML Example" id="movie-services-splide">
                                                            <div className="splide__track">
                                                                <ul className="splide__list">
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-and-saree-draping-image.png" alt="image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/venue-decor-image.png" alt="venue decor" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip ">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/catering-image.png" alt=" catering"  data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image " data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="movie-services-right-arrow">
                                                                <i data-feather="chevron-right" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-9 bg-gallery-show-container d-flex flex-row justify-content-center mt-4">
                                                    <div className="d-flex flex-row justify-content-between gallery-show-container gap-4 p-2">
                                                        <div className="d-flex flex-column justify-content-end align-items-start" >
                                                            <img className="img-fluid couple-images w-100" id="movie-gallery" src="images/services/couple-image-with-wedding-dress.png" alt="couple image with wedding dress"/>
                                                            <img className="img-fluid couple-images d-none movie-gallery-image" src="images/services/couple-image-side-pose-with-flower-mala.png" alt="couple image side pose with flower mala"/>
                                                            <img className="img-fluid couple-images d-none movie-gallery-image" src="images/services/couple-imge-background-wood-door.png" alt="couple image background wood door"/>
                                                            <img className="img-fluid couple-images d-none movie-gallery-image" src="images/services/bride-sit-with-red-saree-groom-stand-image.png" alt="bride sit with red saree groom stand image"/>
                                                            <img className="img-fluid couple-images d-none movie-gallery-image" src="images/services/couple-image-with-green-and-white-dress.png" alt="couple image with green and white dress"/>
                                                        </div>
                                                        <div className=" d-flex flex-column justify-content-between gap-4">
                                                            <img className="img-fluid" id="movie-rings-exchange-preview" src="images/services/couple-promise-hands-image.png" alt="couple promise hands image"/>
                                                            <img className="img-fluid w-100 movie-rings-exchange-images d-none" src="images/services/bride-groom-exchange-rings.png" alt="bride groom exchange rings image"/>
                                                            <img className="img-fluid w-100 movie-rings-exchange-images d-none" src="images/services/groom-hand-image.png" alt="groom hand image"/>
                                                            <img className="img-fluid" id="movie-bride-groom-pose-image-swimming-pool" src="images/services/bride-groom-pose-image-simingpool.png" alt="bride groom pose image swimming pool"/>
                                                            <img className="img-fluid w-100 movie-bride-groom-images d-none" src="images/services/couple-image-at-house.png" alt="couple image at house"/>
                                                            <img className="img-fluid w-100 movie-bride-groom-images d-none" src="images/services/couple-image-with-red-white-dress.png" alt="couple image with red white dress"/>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-between gap-2">
                                                            <img className="img-fluid" id="movie-groom-haldi-preview-image" src="images/services/groom-haldhi-image.png" alt="groom haldhi image"/>
                                                            <img className="img-fluid w-100 movie-haldi-images d-none" src="images/services/couple-image-at-ring-game.png" alt="couple image at ring game"/>
                                                            <img className="img-fluid w-100 movie-haldi-images d-none" src="images/services/couple-image-at-rice-pouring.png" alt="couple image at rice pouring"/>
                                                            <img className="img-fluid" id="movie-bride-preview-image" src="images/services/bride-image-with-red-dress.png" alt="bride image with red dress"/>
                                                            <img className="img-fluid w-100 movie-groom-red-dress d-none" src="images/services/couple-hands-pinky-promise.png" alt="couple hands pinky promise"/>
                                                            <img className="img-fluid w-100 movie-groom-red-dress d-none" src="images/services/couple-marriage-image.png" alt="couple marriage image"/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="public" role="tabpanel" aria-labelledby="public-tab">
                                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                                <div className="col-12 py-4 public-gathering-container" >
                                                    <div className="row d-flex justify-content-center align-items-center gap-2 px-2">
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="public-services-left-arrow">
                                                                <i data-feather="chevron-left" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                        <div className="splide col-12 col-md-9 justify-content-center" role="group" aria-label="Splide Basic HTML Example" id="public-services-splide">
                                                            <div className="splide__track">
                                                                <ul className="splide__list">
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-and-saree-draping-image.png" alt="image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img className="img-fluid" src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/venue-decor-image.png" alt="venue decor" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image" data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="splide__slide">
                                                                        <div className="row">
                                                                            <div className="col-10 col-md-10 px-0">
                                                                                {/* Card Flip */}
                                                                                <div className="card-flip ">
                                                                                    <div className="flip">
                                                                                        <div className="front">
                                                                                            {/* front content */}
                                                                                            <div className="card">
                                                                                                <img className="card-img-top" src="images/services/catering-image.png" alt=" catering"  data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="back">
                                                                                            {/* back content */}
                                                                                            <div className="card">
                                                                                                <img src="images/services/hair-makeup-flipped-image.png" alt="Image " data-holder-rendered="true"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* End Card Flip */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 d-none d-md-flex d-flex flex-column justify-content-center align-items-center">
                                                            <button type="button" className="button-services-left-right p-3 rounded-circle" id="public-services-right-arrow">
                                                                <i data-feather="chevron-right" className="font-color-beige-new-darker"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-9 bg-gallery-show-container d-flex flex-row justify-content-center mt-4">
                                                    <div className="d-flex flex-row justify-content-between gallery-show-container gap-4 p-2">
                                                        <div className="d-flex flex-column justify-content-end align-items-start" >
                                                            <img className="img-fluid couple-images w-100" id="public-gallery" src="images/services/couple-image-with-wedding-dress.png" alt="couple image with wedding dress"/>
                                                            <img className="img-fluid couple-images d-none public-gallery-image" src="images/services/couple-image-side-pose-with-flower-mala.png" alt="couple image side pose with flower mala"/>
                                                            <img className="img-fluid couple-images d-none public-gallery-image" src="images/services/couple-imge-background-wood-door.png" alt="couple image background wood door"/>
                                                            <img className="img-fluid couple-images d-none public-gallery-image" src="images/services/bride-sit-with-red-saree-groom-stand-image.png" alt="bride sit with red saree groom stand image"/>
                                                            <img className="img-fluid couple-images d-none public-gallery-image" src="images/services/couple-image-with-green-and-white-dress.png" alt="couple image with green and white dress"/>
                                                        </div>
                                                        <div className=" d-flex flex-column justify-content-between gap-4">
                                                            <img className="img-fluid" id="public-rings-exchange-preview" src="images/services/couple-promise-hands-image.png" alt="couple promise hands image"/>
                                                            <img className="img-fluid w-100 public-rings-exchange-images d-none" src="images/services/bride-groom-exchange-rings.png" alt="bride groom exchange rings image"/>
                                                            <img className="img-fluid w-100 public-rings-exchange-images d-none" src="images/services/groom-hand-image.png" alt="groom hand image"/>
                                                            <img className="img-fluid" id="public-bride-groom-pose-image-swimming-pool" src="images/services/bride-groom-pose-image-simingpool.png" alt="bride groom pose image swimming pool"/>
                                                            <img className="img-fluid w-100 public-bride-groom-images d-none" src="images/services/couple-image-at-house.png" alt="couple image at house"/>
                                                            <img className="img-fluid w-100 public-bride-groom-images d-none" src="images/services/couple-image-with-red-white-dress.png" alt="couple image with red white dress"/>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-between gap-2">
                                                            <img className="img-fluid" id="public-groom-haldi-preview-image" src="images/services/groom-haldhi-image.png" alt="groom haldhi image"/>
                                                            <img className="img-fluid w-100 public-haldi-images d-none" src="images/services/couple-image-at-ring-game.png" alt="couple image at ring game"/>
                                                            <img className="img-fluid w-100 public-haldi-images d-none" src="images/services/couple-image-at-rice-pouring.png" alt="couple image at rice pouring"/>
                                                            <img className="img-fluid" id="public-bride-preview-image" src="images/services/bride-image-with-red-dress.png" alt="bride image with red dress"/>
                                                            <img className="img-fluid w-100 public-groom-red-dress d-none" src="images/services/couple-hands-pinky-promise.png" alt="couple hands pinky promise"/>
                                                            <img className="img-fluid w-100 public-groom-red-dress d-none" src="images/services/couple-marriage-image.png" alt="couple marriage image"/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-4 pt-md-3 d-flex justify-content-center align-content-center">
                                        <Link to={"/contactus"}>
                                            <button className="font-weight-500p button-contact-us font-size-18 font-color-white font-family-manrope">
                                                contact us
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="pt-md-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Footer section*/}
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default ServiceDetails
